import { CircularProgress } from "@mui/material";
import { styled } from "@mui/material/styles";

const Container = styled("div")(() => ({
    display: "flex",
    flexDirection: "column",
    backgroundColor: "#ffffffaa",
    position: "absolute",
    top: 0,
    bottom: 0,
    left: 0,
    right: 0,
    zIndex: 50,
    "&>*": {
        margin: "auto",
    },
}));

export interface LoadingOverlayProps {
    active: boolean;
}

const LoadingOverlay = ({ active }: LoadingOverlayProps) => {
    return active ? (
        <Container>
            <CircularProgress color="primary" size={70} />
        </Container>
    ) : null;
};

export default LoadingOverlay;
