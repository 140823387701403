import { useEffect, useLayoutEffect, useMemo, useRef, useState } from "react";
import frLocale from "date-fns/locale/fr";
import enLocale from "date-fns/locale/en-GB";
import {
    Alert,
    AlertTitle,
    Autocomplete,
    Box,
    Button,
    Card,
    CardContent,
    Checkbox,
    Collapse,
    createFilterOptions,
    FormControl,
    Grid,
    IconButton,
    InputLabel,
    MenuItem,
    Select,
    TextField,
    Typography,
} from "@mui/material";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { styled } from "@mui/material/styles";
import { usePluginHandler } from "../Contexts/PluginHandler/PluginHandlerContext";
import ModalHeader from "../Components/ModalHeader/ModalHeader";
import { BcfExtensions } from "../Connectors/Models/BcfExtensions";
import { BcfFullTopic } from "../Connectors/Models/BcfFullTopic";
import Viewpoints from "../Components/Viewpoints/Viewpoints";
import { BcfViewpoint } from "../Connectors/Models/BcfViewpoint";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTimes } from "@fortawesome/pro-thin-svg-icons";
import LoadingIndicator from "../Components/LoadingIndicator/LoadingIndicator";
import { useParams } from "react-router-dom";
import { IPluginDataContext, usePluginData } from "../Contexts/PluginData/PluginDataContext";
import { IBcfLabel } from "../Models/IBcfLabel";
import { IBcfIssues } from "../Models/IBcfIssues";
import { useTranslation } from "react-i18next";
import { AssignedUser } from "../Models/AssignedUser";

const Container = styled("div")(() => ({
    display: "flex",
    flexDirection: "column",
}));

const ContentContainer = styled("div")(() => ({
    margin: 10,
    display: "flex",
    flexDirection: "column",
    overflow: "auto",
}));

const ActionsContainer = styled("div")(() => ({
    display: "flex",
}));

const CommentsForm = styled("div")(() => ({
    display: "flex",
    flexWrap: "wrap",
    borderBottom: "1px solid rgba(0, 0, 0, 0.2)",
}));

const CommentFormAction = styled("div")(() => ({
    display: "flex",
    flexDirection: "row",
    flex: 1,
    margin: "10px 0",
}));

const CommentsContainer = styled("div")(() => ({
    display: "flex",
    overflow: "auto",
    flexDirection: "column",
    maxHeight: "calc(100vh - 350px)",
}));

const Comment = styled(Card)(() => ({
    marginTop: "10px",
    overflow: "unset",
    "&:nth-of-type(even)": {
        textAlign: "right",
    },
}));

const localeMap = {
    en: enLocale,
    fr: frLocale,
};

const BcfEditIssue = () => {
    const { plugin } = usePluginHandler();
    const { topicid } = useParams();
    const locale = "en";

    const { activeProject: project } = usePluginData() as IPluginDataContext;
    const [extensions, setExtensions] = useState<BcfExtensions>();

    const [topic, setTopic] = useState<BcfFullTopic>();
    const [viewpoints, setViewpoints] = useState<Array<BcfViewpoint>>([]);
    const [title, setTitle] = useState<string>("");
    const [description, setDescription] = useState<string>("");
    const [assignedUser, setAssignedUser] = useState<AssignedUser | null>(null);
    const [type, setType] = useState<string>("not-set");
    const [priority, setPriority] = useState<string>("not-set");
    const [status, setStatus] = useState<string>("Opened");
    const [stage, setStage] = useState<string>("not-set");
    const [labels, setLabels] = useState<Array<string>>([]);
    const [dueDate, setDueDate] = useState<Date | null>(null);

    const assignedToHint = useRef("");

    const [comment, setComment] = useState<string>("");

    const [error, setError] = useState<IBcfIssues>({ show: false, errors: [] });
    const [isUpdating, setIsUpdating] = useState<boolean>(false);
    const [isLoading, setIsLoading] = useState<boolean>(false);

    const { t } = useTranslation(["common", "bcf"]);

    const userList: Array<AssignedUser> = useMemo(() => {
        if (!extensions?.UserId) return [];

        const notSet = { id: "not-set", label: t("DropdownOptionNotSet", { ns: "bcf" }) };
        const users = extensions.UserId.map((user) => ({ id: user, label: user }));
        users.sort((a, b) => a.label.localeCompare(b.label));

        if (topic?.assignedTo) {
            const assigned = users.find((x) => x.id === topic.assignedTo);
            if (assigned) setAssignedUser(assigned);
        } else {
            setAssignedUser(notSet);
        }

        return [notSet, ...users];
    }, [t, topic, extensions?.UserId]);

    useLayoutEffect(() => {
        setIsLoading(true);
    }, [topicid]);

    useLayoutEffect(() => {
        if (!plugin || !project || !topicid) return;

        plugin?.getBcfExtensionsAsync(project.Cloud.Id, project.Id).then(setExtensions);
        plugin?.getBcfTopicAsync(project.Cloud.Id, project.Id, topicid).then((topic) => {
            setTopic(topic);
            setTopicInfo(topic);
            setIsLoading(false);
        });

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [plugin, project, topicid]);

    useEffect(() => {
        if (!plugin || !project || !topic || !isUpdating) return;

        const assignedTo = assignedUser?.id;
        const bcf: BcfFullTopic = {
            ...topic,
            title,
            description,
            labels,
            type: type === "not-set" ? undefined : type,
            status: status === "not-set" ? undefined : status,
            stage: stage === "not-set" ? undefined : stage,
            priority: priority === "not-set" ? undefined : priority,
            assignedTo: assignedTo === "not-set" ? undefined : assignedTo,
            dueDate,
            viewpoints,
        };

        plugin.updateBcfTopicAsync(project.Cloud.Id, project.Id, bcf).then((result) => {
            setIsUpdating(false);
            setTopic(bcf);
            if (!result) setError({ show: true, errors: [], message: "Unknown server error." });
            else {
                localStorage.setItem("bcf-refresh-overview", "1");
                plugin.closeDialogAsync("existing-bcf-topic");
            }
        });

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isUpdating]);

    const setTopicInfo = (topic: BcfFullTopic) => {
        setTitle(topic.title);
        setDescription(topic.description);
        setViewpoints(topic.viewpoints);
        setType(topic.type ? topic.type : "not-set");
        setPriority(topic.priority ? topic.priority : "not-set");
        setStatus(topic.status ? topic.status : "not-set");
        setStage(topic.stage ? topic.stage : "not-set");
        setLabels(topic.labels);
        setDueDate(topic.dueDate);

        setComment("");

        if (topic.assignedTo) {
            const assigned = userList.find((x) => x.id === topic.assignedTo);
            if (assigned) setAssignedUser(assigned);
        }
    };

    const onAddViewpoint = () => {
        if (!plugin || !project) return;

        plugin.extractViewpointAsync().then((vp) => {
            if (!vp) return;
            setViewpoints((vps) => [...vps, vp]);
        });
    };

    const onRemoveViewpoint = (vp: BcfViewpoint) => {
        const vps = viewpoints.filter((x) => x !== vp);
        setViewpoints(vps);
    };

    const onUpdate = () => {
        if (validateForm()) setIsUpdating(true);
    };

    const validateForm = (): boolean => {
        const error: IBcfIssues = { show: false, errors: [] };

        if (title.trim().length === 0) error.errors.push("bcf-title");
        error.show = error.errors.length > 0;

        setError(error);

        return !error.show;
    };

    const hasError = (identifier: string) => {
        return error.errors.some((x) => x === identifier);
    };

    const clearErrors = (errorSource: string) => {
        const currError = { ...error };
        if (currError.errors) {
            currError.errors = [...currError.errors.filter((x) => x !== errorSource)];
        }

        if (currError.errors.length === 0) {
            currError.show = false;
        }

        setError(currError);
    };

    const onCancel = () => {
        if (!topic) return;

        // TODO: Add confirmation dialog
        setTopicInfo(topic);
    };

    const onAddComment = () => {
        if (!plugin || !project || !topic) return;

        plugin
            .addBcfTopicCommentAsync(project.Cloud.Id, project.Id, topic.guid!, {
                comment,
            })
            .then((x) => {
                setComment("");
                const updatedTopic = { ...topic };
                updatedTopic.comments = [x, ...updatedTopic.comments];
                setTopic(updatedTopic);
            });
    };

    return (
        <Container>
            <ModalHeader title={t("ModalEditTitle", { ns: "bcf" })} />

            <Collapse in={error.show} sx={{ minHeight: "unset !important" }}>
                <Alert
                    severity="error"
                    action={
                        <IconButton
                            aria-label="close"
                            color="inherit"
                            size="small"
                            onClick={() => {
                                setError((x) => ({ ...x, show: false }));
                            }}
                        >
                            <FontAwesomeIcon icon={faTimes} />
                        </IconButton>
                    }
                    sx={{ marginTop: "10px" }}
                >
                    <AlertTitle>{t("EditErrorTitle", { ns: "bcf" })}</AlertTitle>
                    {t("EditErrorMessage", { ns: "bcf" })}
                    <br />
                    {error?.message}
                </Alert>
            </Collapse>

            <ContentContainer>
                {isUpdating || isLoading ? (
                    <div style={{ display: "flex" }}>
                        <LoadingIndicator />
                    </div>
                ) : (
                    <Grid container spacing={2} sx={{ marginTop: "-6px" }}>
                        <Grid item xs={8} sm={8}>
                            <Grid container spacing={2}>
                                <Grid
                                    item
                                    xs={12}
                                    sm={12}
                                    sx={{ display: "flex", flexDirection: "row", flexWrap: "wrap" }}
                                >
                                    <Viewpoints
                                        viewpoints={viewpoints}
                                        onAdd={() => onAddViewpoint()}
                                        onRemove={(vp) => onRemoveViewpoint(vp)}
                                    />
                                    <Box sx={{ minWidth: 300, flex: "1" }}>
                                        <Grid container spacing={2}>
                                            <Grid item xs={12} sm={12}>
                                                <TextField
                                                    label={t("FormInputTitleLabel", { ns: "bcf" })}
                                                    value={title}
                                                    onChange={(e) => {
                                                        setTitle(e.target.value);
                                                        clearErrors("bcf-title");
                                                    }}
                                                    error={hasError("bcf-title")}
                                                    helperText={
                                                        hasError("bcf-title") &&
                                                        t("FormInputTitleHelperText", { ns: "bcf" })
                                                    }
                                                    required
                                                    fullWidth
                                                />
                                            </Grid>
                                            <Grid item xs={12} sm={12}>
                                                <TextField
                                                    label={t("FormInputDescriptionLabel", { ns: "bcf" })}
                                                    value={description}
                                                    onChange={(e) => {
                                                        setDescription(e.target.value);
                                                    }}
                                                    multiline
                                                    rows={5}
                                                    fullWidth
                                                />
                                            </Grid>
                                        </Grid>
                                    </Box>
                                </Grid>
                                <Grid item xs={12} sm={6}>
                                    <FormControl fullWidth>
                                        <Autocomplete
                                            id="assigned-to-select"
                                            onChange={(_, newValue) => setAssignedUser(newValue)}
                                            options={userList}
                                            value={assignedUser}
                                            handleHomeEndKeys={false}
                                            noOptionsText={t("DropdownOptionNone")}
                                            isOptionEqualToValue={(option, value) => option.id === value.id}
                                            onKeyDown={(event) => {
                                                if (event.key === "Tab") {
                                                    if (assignedToHint.current) {
                                                        const user = userList.find(
                                                            (x) => x.label === assignedToHint.current,
                                                        );
                                                        if (user) {
                                                            setAssignedUser(user);
                                                            assignedToHint.current = "";
                                                        }
                                                        event.preventDefault();
                                                    }
                                                }
                                            }}
                                            onBlur={() => {
                                                assignedToHint.current = "";
                                            }}
                                            disablePortal
                                            filterOptions={(options, state) => {
                                                const displayOptions = options.filter((option) =>
                                                    option.label
                                                        .toLowerCase()
                                                        .trim()
                                                        .includes(state.inputValue.toLowerCase().trim()),
                                                );

                                                return displayOptions;
                                            }}
                                            renderInput={(params) => {
                                                return (
                                                    <Box sx={{ position: "relative" }}>
                                                        <Typography
                                                            sx={{
                                                                position: "absolute",
                                                                opacity: 0.5,
                                                                left: 14,
                                                                top: 16,
                                                            }}
                                                        >
                                                            {assignedToHint.current}
                                                        </Typography>
                                                        <TextField
                                                            {...params}
                                                            onChange={(e) => {
                                                                const newValue = e.target.value;
                                                                const matchingOption = userList.find((option) =>
                                                                    option.label.startsWith(newValue),
                                                                );

                                                                if (newValue && matchingOption) {
                                                                    assignedToHint.current = matchingOption.label;
                                                                } else {
                                                                    assignedToHint.current = "";
                                                                }
                                                            }}
                                                            label={t("FormInputAssignedToLabel", { ns: "bcf" })}
                                                        />
                                                    </Box>
                                                );
                                            }}
                                        />
                                    </FormControl>
                                </Grid>
                                <Grid item xs={12} sm={6}>
                                    <FormControl fullWidth>
                                        <InputLabel id="type-label">
                                            {t("FormInputTypeLabel", { ns: "bcf" })}
                                        </InputLabel>
                                        <Select
                                            id="type-select"
                                            labelId="type-label"
                                            label={t("FormInputTypeLabel", { ns: "bcf" })}
                                            value={type}
                                            onChange={(e) => setType(e.target.value as string)}
                                            required
                                        >
                                            {!extensions?.Type || extensions.Type.length === 0 ? (
                                                <MenuItem value={""}>{t("DropdownOptionNone")}</MenuItem>
                                            ) : (
                                                <MenuItem value={"not-set"}>
                                                    {t("DropdownOptionNotSet", { ns: "bcf" })}
                                                </MenuItem>
                                            )}
                                            {extensions?.Type.map((option, index) => (
                                                <MenuItem value={option} key={index}>
                                                    {option}
                                                </MenuItem>
                                            ))}
                                        </Select>
                                    </FormControl>
                                </Grid>
                                <Grid item xs={12} sm={6}>
                                    <FormControl fullWidth>
                                        <InputLabel id="priority-label">
                                            {t("FormInputPriorityLabel", { ns: "bcf" })}
                                        </InputLabel>
                                        <Select
                                            id="priority-select"
                                            labelId="priority-label"
                                            label={t("FormInputPriorityLabel", { ns: "bcf" })}
                                            value={priority}
                                            onChange={(e) => setPriority(e.target.value as string)}
                                            required
                                        >
                                            {!extensions?.Priority || extensions.Priority.length === 0 ? (
                                                <MenuItem value={""}>{t("DropdownOptionNone")}</MenuItem>
                                            ) : (
                                                <MenuItem value={"not-set"}>
                                                    {t("DropdownOptionNotSet", { ns: "bcf" })}
                                                </MenuItem>
                                            )}
                                            {extensions?.Priority.map((option, index) => (
                                                <MenuItem value={option} key={index}>
                                                    {option}
                                                </MenuItem>
                                            ))}
                                        </Select>
                                    </FormControl>
                                </Grid>
                                <Grid item xs={12} sm={6}>
                                    <FormControl fullWidth>
                                        <InputLabel id="status-label">
                                            {t("FormInputStatusLabel", { ns: "bcf" })}
                                        </InputLabel>
                                        <Select
                                            id="status-select"
                                            labelId="status-label"
                                            label="Status"
                                            value={status}
                                            onChange={(e) => setStatus(e.target.value as string)}
                                            required
                                        >
                                            {!extensions?.Status || extensions.Status.length === 0 ? (
                                                <MenuItem value={""}>{t("DropdownOptionNone")}</MenuItem>
                                            ) : null}
                                            {extensions?.Status.map((option, index) => (
                                                <MenuItem value={option} key={index}>
                                                    {option}
                                                </MenuItem>
                                            ))}
                                        </Select>
                                    </FormControl>
                                </Grid>
                                <Grid item xs={12} sm={6}>
                                    <FormControl fullWidth>
                                        <InputLabel id="stage-label">
                                            {t("FormInputPhaseLabel", { ns: "bcf" })}
                                        </InputLabel>
                                        <Select
                                            id="stage-select"
                                            labelId="stage-label"
                                            label={t("FormInputPhaseLabel", { ns: "bcf" })}
                                            value={stage}
                                            onChange={(e) => setStage(e.target.value as string)}
                                            required
                                        >
                                            {!extensions?.Stage || extensions.Stage.length === 0 ? (
                                                <MenuItem value={""}>{t("DropdownOptionNone")}</MenuItem>
                                            ) : (
                                                <MenuItem value={"not-set"}>
                                                    {t("DropdownOptionNotSet", { ns: "bcf" })}
                                                </MenuItem>
                                            )}
                                            {extensions?.Stage.map((option, index) => (
                                                <MenuItem value={option} key={index}>
                                                    {option}
                                                </MenuItem>
                                            ))}
                                        </Select>
                                    </FormControl>
                                </Grid>
                                <Grid item xs={12} sm={6}>
                                    <FormControl fullWidth>
                                        <LocalizationProvider
                                            dateAdapter={AdapterDateFns}
                                            adapterLocale={localeMap[locale]}
                                        >
                                            <DatePicker
                                                label={t("FormInputDueDateLabel", { ns: "bcf" })}
                                                value={dueDate}
                                                onChange={(newValue) => setDueDate(newValue)}
                                            />
                                        </LocalizationProvider>
                                    </FormControl>
                                </Grid>
                                <Grid item xs={12} sm={12}>
                                    <FormControl fullWidth>
                                        <Autocomplete
                                            id="labels-select"
                                            onChange={(event, newValue) =>
                                                setLabels(
                                                    newValue
                                                        .map((x) => {
                                                            if (typeof x === "string") {
                                                                return x;
                                                            }

                                                            return x.inputValue ?? x.label;
                                                        })
                                                        .filter((value, index, self) => self.indexOf(value) === index),
                                                )
                                            }
                                            filterOptions={(options, params) => {
                                                const filtered = createFilterOptions<IBcfLabel>()(options, params);

                                                const { inputValue } = params;
                                                const isExisting = options.some(
                                                    (option) => inputValue === option.label,
                                                );
                                                if (inputValue !== "" && !isExisting) {
                                                    filtered.push({
                                                        inputValue,
                                                        label: t("FormInputLabelsInsertLabel", {
                                                            ns: "bcf",
                                                            label: inputValue,
                                                        }),
                                                    });
                                                }

                                                return filtered;
                                            }}
                                            selectOnFocus
                                            clearOnBlur
                                            handleHomeEndKeys
                                            options={extensions?.Label.map((x) => ({ label: x } as IBcfLabel)) ?? []}
                                            value={labels}
                                            freeSolo
                                            multiple
                                            getOptionLabel={(option) => {
                                                if (typeof option === "string") return option;
                                                if (option.inputValue) return option.inputValue;
                                                return option.label;
                                            }}
                                            renderOption={(props, option) => (
                                                // eslint-disable-next-line jsx-a11y/role-supports-aria-props
                                                <li
                                                    {...props}
                                                    aria-selected={
                                                        labels.indexOf(option.inputValue ?? option.label) > -1
                                                    }
                                                >
                                                    <Checkbox
                                                        style={{ marginRight: 8 }}
                                                        checked={labels.indexOf(option.inputValue ?? option.label) > -1}
                                                    />
                                                    {option.label}
                                                </li>
                                            )}
                                            renderInput={(params) => (
                                                <TextField
                                                    {...params}
                                                    label={t("FormInputLabelsLabel", { ns: "bcf" })}
                                                    placeholder={t("FormInputLabelsPlaceholder", { ns: "bcf" })}
                                                />
                                            )}
                                        />
                                    </FormControl>
                                </Grid>
                                <Grid item xs={12} sm={12} md={6}>
                                    <ActionsContainer>
                                        <Button fullWidth onClick={() => onUpdate()}>
                                            {t("ButtonEditTopicFormSaveLabel", { ns: "bcf" })}
                                        </Button>
                                        <Button
                                            fullWidth
                                            sx={{ marginLeft: "8px" }}
                                            variant="outlined"
                                            onClick={() => onCancel()}
                                        >
                                            {t("ButtonEditTopicFormUndoLabel", { ns: "bcf" })}
                                        </Button>
                                    </ActionsContainer>
                                </Grid>
                            </Grid>
                        </Grid>
                        <Grid item xs={4} sm={4}>
                            <CommentsForm>
                                <TextField
                                    label={t("FormInputCommentLabel", { ns: "bcf" })}
                                    value={comment}
                                    onChange={(e) => {
                                        setComment(e.target.value);
                                    }}
                                    multiline
                                    rows={5}
                                    fullWidth
                                    required
                                    sx={{ flex: "1 1 100%" }}
                                />
                                <CommentFormAction>
                                    <Button
                                        fullWidth
                                        sx={{ marginRight: "10px" }}
                                        onClick={() => onAddComment()}
                                        disabled={comment.length === 0}
                                    >
                                        {t("ButtonCommentFormSendLabel", { ns: "bcf" })}
                                    </Button>
                                    <Button
                                        fullWidth
                                        sx={{ marginLeft: "10px" }}
                                        variant="outlined"
                                        onClick={() => setComment("")}
                                    >
                                        {t("ButtonCommentFormCancelLabel", { ns: "bcf" })}
                                    </Button>
                                </CommentFormAction>
                            </CommentsForm>
                            <CommentsContainer>
                                {topic?.comments.map((comment) => (
                                    <Comment elevation={0} key={comment.guid}>
                                        <CardContent>
                                            <Typography sx={{ fontSize: 14 }} color="text.secondary" gutterBottom>
                                                {comment.author}
                                            </Typography>
                                            <Typography variant="h5" component="div">
                                                {comment.date?.toString()}
                                            </Typography>
                                            <Typography variant="body2">{comment.comment}</Typography>
                                        </CardContent>
                                    </Comment>
                                ))}
                            </CommentsContainer>
                        </Grid>
                    </Grid>
                )}
            </ContentContainer>
        </Container>
    );
};

export default BcfEditIssue;
