import { useLayoutEffect, useRef, useState } from "react";
import frLocale from "date-fns/locale/fr";
import enLocale from "date-fns/locale/en-GB";
import {
    Autocomplete,
    Button,
    Card,
    CardActionArea,
    CardActions,
    CardContent,
    CardMedia,
    Checkbox,
    Chip,
    Collapse,
    Divider,
    FormControl,
    FormControlLabel,
    Grid,
    IconButton,
    Menu,
    MenuItem,
    Radio,
    RadioGroup,
    TextField,
    Typography,
} from "@mui/material";
import AdapterDateFns from "@mui/lab/AdapterDateFns";
import DatePicker from "@mui/lab/DatePicker";
import LocalizationProvider from "@mui/lab/LocalizationProvider";
import { styled } from "@mui/material/styles";
import { usePluginHandler } from "../Contexts/PluginHandler/PluginHandlerContext";
import PageHeader from "../Components/PageHeader/PageHeader";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEllipsisV, faPlus, faSlidersV } from "@fortawesome/pro-thin-svg-icons";
import LoadingIndicator from "../Components/LoadingIndicator/LoadingIndicator";
import { BcfTopic } from "../Connectors/Models/BcfTopic";
import { BcfExtensions } from "../Connectors/Models/BcfExtensions";
import { IPluginDataContext, usePluginData } from "../Contexts/PluginData/PluginDataContext";
import { isAfter, isBefore, isSameDay } from "date-fns";
import { useTranslation } from "react-i18next";

const Container = styled("div")(() => ({
    display: "flex",
    flexDirection: "column",
}));

const ContentContainer = styled("div")(() => ({
    margin: 10,
    display: "flex",
    flexDirection: "column",
}));

const LoadingIndicatorContainer = styled("div")(() => ({
    height: "50vh",
    width: "100%",
}));

const ActionsContainer = styled("div")(() => ({
    minHeight: "fit-content",
    display: "flex",
    flexWrap: "wrap",
}));

const MessageContainer = styled("div")(() => ({
    margin: "10px auto",
    textAlign: "center",
}));

const IssuesContainer = styled("div")(() => ({
    display: "flex",
    flexWrap: "wrap",
    overflow: "auto",
    margin: "0 -10px"
}));

const FilterCard = styled(Card)(() => ({
    margin: "10px 10px",
    flexDirection: "row",
    flex: 1,
    flexWrap: "wrap",
}));

const IssueCard = styled(Card)(() => ({
    margin: "10px 10px",
    textAlign: "center",
    display: "flex",
    flexDirection: "row",
    minWidth: 350,
    maxWidth: 700,
    flex: 1,
    flexWrap: "wrap",
}));

const Spacer = styled("div")(() => ({
    margin: "0 10px",
    minWidth: 350,
    maxWidth: 700,
    height: 0,
    flex: 1,
}));

const localeMap = {
    en: enLocale,
    fr: frLocale,
};

const maskMap = {
    en: "__/__/____",
    fr: "__/__/____",
};
const locale = "en";

interface IBcfFilter {
    title?: string;
    assignedTo: Array<string>;
    type: Array<string>;
    priority: Array<string>;
    status: Array<string>;
    stage: Array<string>;
    dueDateHandler: "on" | "before" | "after";
    dueDate: Date | null;
    labels: Array<string>;
}

const BcfOverview = () => {
    const { plugin } = usePluginHandler();
    const { activeProject: project } = usePluginData() as IPluginDataContext;

    const [extensions, setExtensions] = useState<BcfExtensions>();
    const [topics, setTopics] = useState<Array<BcfTopic>>([]);
    const [isLoading, setIsLoading] = useState<boolean>(true);
    const [showFiltering, setShowFiltering] = useState<boolean>(false);

    const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null);
    const [menuTarget, setMenuTarget] = useState<string | null>(null);
    const open = Boolean(anchorEl);

    const [activeFilter, setActiveFilter] = useState<IBcfFilter>();
    const [filteredTopics, setFilteredTopics] = useState<Array<BcfTopic>>([]);
    const [displayCount, setDisplayCount] = useState<Number>(0);

    const refreshTimerHandle = useRef<number>();
    const { t } = useTranslation(["common", "bcf"]);

    useLayoutEffect(() => {
        if (!plugin || !project) return;

        plugin?.getBcfExtensionsAsync(project.Cloud.Id, project.Id).then(setExtensions);
        plugin?.getBcfTopicsAsync(project.Cloud.Id, project.Id).then((topics) => {
            setTopics(topics);
            setIsLoading(false);
        });

        refreshTimerHandle.current = window.setInterval(() => {
            if (localStorage.getItem("bcf-refresh-overview") === "1") {
                localStorage.setItem("bcf-refresh-overview", "0");
                plugin?.getBcfTopicsAsync(project.Cloud.Id, project.Id).then((topics) => {
                    setTopics(topics);
                });
            }
        }, 500);

        return () => {
            window.clearInterval(refreshTimerHandle.current);
        };
    }, [plugin, project]);

    useLayoutEffect(() => {
        let filteredTopics = [...topics];

        if (activeFilter) {
            if (activeFilter.title) {
                filteredTopics = filteredTopics.filter(
                    (topic) => topic.Title.toLocaleLowerCase().indexOf(activeFilter.title!.toLocaleLowerCase()) > -1
                );
            }

            if (activeFilter.assignedTo.length > 0) {
                filteredTopics = filteredTopics.filter((topic) =>
                    activeFilter.assignedTo.some((assignedTo) => assignedTo === topic.AssignedTo)
                );
            }

            if (activeFilter.type.length > 0) {
                filteredTopics = filteredTopics.filter((topic) => activeFilter.type.some((type) => type === topic.Type));
            }

            if (activeFilter.priority.length > 0) {
                filteredTopics = filteredTopics.filter((topic) => activeFilter.priority.some((priority) => priority === topic.Priority));
            }

            if (activeFilter.status.length > 0) {
                filteredTopics = filteredTopics.filter((topic) => activeFilter.status.some((status) => status === topic.Status));
            }

            if (activeFilter.stage.length > 0) {
                filteredTopics = filteredTopics.filter((topic) => activeFilter.stage.some((stage) => stage === topic.Stage));
            }

            if (activeFilter.dueDate) {
                const date = activeFilter!.dueDate;
                switch (activeFilter.dueDateHandler) {
                    case "on":
                        filteredTopics = filteredTopics.filter((topic) => {
                            const topicDate = new Date(topic.DueDate);
                            return topic.DueDate && isSameDay(topicDate, date);
                        });
                        break;
                    case "before":
                        filteredTopics = filteredTopics.filter((topic) => {
                            const topicDate = new Date(topic.DueDate);
                            return topic.DueDate && isBefore(topicDate, date);
                        });
                        break;
                    case "after":
                        filteredTopics = filteredTopics.filter((topic) => {
                            const topicDate = new Date(topic.DueDate);
                            return topic.DueDate && isAfter(topicDate, date);
                        });
                        break;
                }
            }

            if (activeFilter.labels.length > 0) {
                filteredTopics = filteredTopics.filter((topic) =>
                    activeFilter.labels.some((label) => topic.Labels.some((topicLabel) => topicLabel === label))
                );
            }
        }

        setFilteredTopics(filteredTopics);
        setDisplayCount(filteredTopics.length);
    }, [topics, activeFilter]);

    const onCreateTopic = () => {
        if (!plugin) return;
        plugin.openDialogAsync("create-bcf-topic", `${window.location.href}/topics/new`, 730, 900);
    };

    const onActivateTopic = (guid: string) => {
        if (!plugin) return;
        plugin.openDialogAsync("existing-bcf-topic", `${window.location.href}/topics/${guid}`, 730, 1000);
    };

    const onOpenTopicMenu = (event: React.MouseEvent<HTMLButtonElement>, guid: string) => {
        event.preventDefault();
        event.stopPropagation();

        setAnchorEl(event.currentTarget);
        setMenuTarget(guid);
    };

    const hasImages = (): boolean | undefined => {
        if (!menuTarget) return true;

        const topic = topics.find((x) => x.Guid === menuTarget);
        if (!topic) return true;

        return topic.Images.length === 0;
    };
    const onEditTopic = () => {};

    const onViewInModel = () => {
        if (!plugin || !project || !menuTarget) return;
        plugin.getBcfTopicAsync(project.Cloud.Id, project.Id, menuTarget).then((fullTopic) => {
            const topic = topics.find((x) => x.Guid === menuTarget);
            plugin.showViewpointAsync(fullTopic.viewpoints.find((x) => x.snapshot.snapshotData === topic!.Images[0])!);
            onCloseTopicMenu();
        });
    };

    const onDeleteTopic = () => {
        if (!plugin || !project || !menuTarget) return;

        plugin.removeBcfTopicAsync(project.Cloud.Id, project.Id, menuTarget);
        const filtered = topics.filter((x) => x.Guid !== menuTarget);
        setTopics(filtered);
        onCloseTopicMenu();
    };

    const onCloseTopicMenu = () => {
        setAnchorEl(null);
        setMenuTarget(null);
    };

    const renderIssues = () => {
        if (!filteredTopics || filteredTopics.length === 0)
            return (
                <MessageContainer>
                    <Typography>{t("NoTopics", { ns: "bcf" })}</Typography>
                </MessageContainer>
            );

        return filteredTopics?.map((x) => (
            <IssueCard key={x.Guid}>
                <CardActions sx={{ width: "100%", display: "flex", placeContent: "center", marginTop: "auto" }}>
                    <Typography variant="h3" sx={{ textAlign: "left" }}>
                        {x.Title}
                    </Typography>
                    <IconButton
                        sx={{
                            marginLeft: "auto !important",
                            padding: 0,
                            width: 36,
                            height: 36,
                            "&:hover": { backgroundColor: "rgba(0, 0, 0, 0.045)" },
                        }}
                        onClick={(e) => onOpenTopicMenu(e, x.Guid)}
                    >
                        <FontAwesomeIcon icon={faEllipsisV} />
                    </IconButton>
                </CardActions>
                <CardActionArea
                    sx={{
                        display: "flex",
                        alignItems: "flex-start",
                        justifyContent: "flex-start",
                        borderTop: "1px solid rgba(0, 0, 0, 0.12)",
                        alignSelf: "flex-end",
                    }}
                    onClick={() => onActivateTopic(x.Guid)}
                >
                    <CardMedia
                        component="img"
                        image={
                            x.Images.length > 0
                                ? x.Images[0]
                                : "https://upload.wikimedia.org/wikipedia/commons/6/65/No-Image-Placeholder.svg"
                        }
                        alt="Viewpoint"
                        sx={{ minWidth: 150, maxHeight: 150, width: 150, height: 150, flex: 0 }}
                    />
                    <CardContent sx={{ display: "flex", flexDirection: "column", textAlign: "left", paddingBottom: "10px !important" }}>
                        <Grid container spacing={1} sx={{ marginTop: "auto" }}>
                            <Grid item xs={6} sm={6}>
                                <Typography variant="h5">{t("FormInputTypeLabel", { ns: "bcf" })}</Typography>
                            </Grid>
                            <Grid item xs={6} sm={6}>
                                <Typography variant="h5">{x.Type}</Typography>
                            </Grid>
                            <Grid item xs={6} sm={6}>
                                <Typography variant="h5">{t("FormInputPriorityLabel", { ns: "bcf" })}</Typography>
                            </Grid>
                            <Grid item xs={6} sm={6}>
                                <Typography variant="h5">{x.Priority}</Typography>
                            </Grid>
                            <Grid item xs={6} sm={6}>
                                <Typography variant="h5">{t("FormInputStatusLabel", { ns: "bcf" })}</Typography>
                            </Grid>
                            <Grid item xs={6} sm={6}>
                                <Typography variant="h5">{x.Status}</Typography>
                            </Grid>
                            <Grid item xs={6} sm={6}>
                                <Typography variant="h5">{t("FormInputAssignedToLabel", { ns: "bcf" })}</Typography>
                            </Grid>
                            <Grid item xs={6} sm={6}>
                                <Typography variant="h5">{x.AssignedTo}</Typography>
                            </Grid>
                        </Grid>
                    </CardContent>
                </CardActionArea>
            </IssueCard>
        ));
    };

    return (
        <Container>
            <PageHeader title={t("PageTitle", { ns: "bcf" })} />

            <ContentContainer>
                <ActionsContainer>
                    <Button
                        onClick={() => onCreateTopic()}
                        startIcon={<FontAwesomeIcon icon={faPlus} />}
                        sx={{ flex: "calc(50% - 4px)", whiteSpace: "nowrap" }}
                    >
                        {t("ButtonCreateTopicLabel", { ns: "bcf" })}
                    </Button>
                    <Button
                        onClick={() => setShowFiltering((x) => !x)}
                        startIcon={<FontAwesomeIcon icon={faSlidersV} />}
                        sx={{ marginLeft: "8px", flex: "calc(50% - 4px)", whiteSpace: "nowrap" }}
                    >
                        {t("ButtonFilterLabel", { ns: "bcf" })}
                    </Button>

                    {activeFilter && (
                        <div style={{ display: "flex", flex: "100%", margin: "10px 10px 0 10px", alignItems: "center" }}>
                            <Typography variant="h5" sx={{ flex: 1 }}>
                                {topics.length > 0 &&
                                    t("FilterMessage", { ns: "bcf", displayCount: displayCount, totalCount: topics.length })}
                            </Typography>
                            <Chip
                                label={t("ButtonClearFiltersLabel", { ns: "bcf" })}
                                onClick={() => setActiveFilter(undefined)}
                                onDelete={() => setActiveFilter(undefined)}
                            />
                        </div>
                    )}
                </ActionsContainer>

                <IssuesContainer>
                    <BcfFilter
                        extensions={extensions}
                        visible={showFiltering}
                        activeFilter={activeFilter}
                        onApply={(filter) => {
                            setActiveFilter(filter);
                            setShowFiltering(false);
                        }}
                        onClear={() => setActiveFilter(undefined)}
                    />

                    {isLoading ? (
                        <LoadingIndicatorContainer>
                            <LoadingIndicator />
                        </LoadingIndicatorContainer>
                    ) : (
                        <>
                            {renderIssues()}
                            {[...Array(topics?.length ?? 0)].map((_, index) => (
                                <Spacer key={index} />
                            ))}

                            <Menu
                                id="basic-menu"
                                anchorEl={anchorEl}
                                open={open}
                                onClose={onCloseTopicMenu}
                                transformOrigin={{ horizontal: "right", vertical: "top" }}
                                anchorOrigin={{ horizontal: "right", vertical: "bottom" }}
                            >
                                <MenuItem onClick={onEditTopic}>{t("TopicMenuOptionOpen", { ns: "bcf" })}</MenuItem>
                                <MenuItem onClick={onViewInModel} disabled={hasImages()}>
                                    {t("TopicMenuOptionView", { ns: "bcf" })}
                                </MenuItem>
                                <Divider />
                                <MenuItem onClick={onDeleteTopic}>{t("TopicMenuOptionDelete", { ns: "bcf" })}</MenuItem>
                            </Menu>
                        </>
                    )}
                </IssuesContainer>
            </ContentContainer>
        </Container>
    );
};

export default BcfOverview;

interface IBcfFilterProps {
    extensions?: BcfExtensions;
    visible: boolean;
    activeFilter?: IBcfFilter;
    onApply: (filter: IBcfFilter) => void;
    onClear: () => void;
}

const BcfFilter = ({ extensions, visible, activeFilter, onApply, onClear }: IBcfFilterProps) => {
    const [title, setTitle] = useState<string>("");
    const [assignedTo, setAssignedTo] = useState<Array<string>>([]);
    const [type, setType] = useState<Array<string>>([]);
    const [priority, setPriority] = useState<Array<string>>([]);
    const [status, setStatus] = useState<Array<string>>([]);
    const [stage, setStage] = useState<Array<string>>([]);
    const [labels, setLabels] = useState<Array<string>>([]);
    const [dueDateHandler, setDueDateHandler] = useState<"on" | "before" | "after">("on");
    const [dueDate, setDueDate] = useState<Date | null>(null);

    const { t } = useTranslation(["common", "bcf"]);

    useLayoutEffect(() => {
        if (activeFilter) {
            setTitle(activeFilter.title ?? "");
            setAssignedTo(activeFilter.assignedTo);
            setType(activeFilter.type);
            setPriority(activeFilter.priority);
            setStatus(activeFilter.status);
            setStage(activeFilter.stage);
            setLabels(activeFilter.labels);
            setDueDateHandler(activeFilter.dueDateHandler);
            setDueDate(activeFilter.dueDate);
        } else onClearFilter();

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [activeFilter]);

    const onApplyFilter = () => {
        onApply({ title, assignedTo, type, priority, status, stage, labels, dueDateHandler, dueDate });
    };

    const onClearFilter = () => {
        setTitle("");
        setAssignedTo([]);
        setType([]);
        setPriority([]);
        setStatus([]);
        setStage([]);
        setLabels([]);
        setDueDateHandler("on");
        setDueDate(null);

        onClear();
    };

    return (
        <Collapse in={visible} sx={{width: "100%"}}>
            <FilterCard>
                <CardActions sx={{ width: "100%", display: "flex", marginTop: "auto", height: "52px" }}>
                    <Typography variant="h3">{t("FilterTitle", { ns: "bcf" })}</Typography>
                </CardActions>
                <CardContent
                    sx={{
                        borderTop: "1px solid rgba(0, 0, 0, 0.12)",
                        borderBottom: "1px solid rgba(0, 0, 0, 0.12)",
                    }}
                >
                    <Grid container spacing={2} sx={{ overflow: "auto" }}>
                        <Grid item xs={12} sm={12} md={6}>
                            <TextField
                                label={t("FormInputTitleLabel", { ns: "bcf" })}
                                value={title}
                                onChange={(e) => setTitle(e.target.value)}
                                fullWidth
                            />
                        </Grid>
                        <Grid item xs={12} sm={12} md={6}>
                            <FormControl fullWidth>
                                <Autocomplete
                                    id="assigned-to-select"
                                    disableCloseOnSelect
                                    options={extensions?.UserId ?? []}
                                    renderOption={(props, option) => (
                                        <li {...props}>
                                            <Checkbox style={{ marginRight: 8 }} checked={assignedTo.indexOf(option) > -1} />
                                            {option}
                                        </li>
                                    )}
                                    getOptionLabel={(option) => option}
                                    multiple
                                    fullWidth
                                    onChange={(event, value) => setAssignedTo(value)}
                                    renderInput={(params) => <TextField {...params} label={t("FormInputAssignedToLabel", { ns: "bcf" })} />}
                                />
                            </FormControl>
                        </Grid>
                        <Grid item xs={12} sm={12} md={6}>
                            <FormControl fullWidth>
                                <Autocomplete
                                    id="type-select"
                                    disableCloseOnSelect
                                    options={extensions?.Type ?? []}
                                    renderOption={(props, option) => (
                                        <li {...props}>
                                            <Checkbox style={{ marginRight: 8 }} checked={type.indexOf(option) > -1} />
                                            {option}
                                        </li>
                                    )}
                                    getOptionLabel={(option) => option}
                                    multiple
                                    fullWidth
                                    onChange={(event, value) => setType(value)}
                                    renderInput={(params) => <TextField {...params} label={t("FormInputTypeLabel", { ns: "bcf" })} />}
                                />
                            </FormControl>
                        </Grid>
                        <Grid item xs={12} sm={12} md={6}>
                            <FormControl fullWidth>
                                <Autocomplete
                                    id="priority-select"
                                    disableCloseOnSelect
                                    options={extensions?.Priority ?? []}
                                    renderOption={(props, option) => (
                                        <li {...props}>
                                            <Checkbox style={{ marginRight: 8 }} checked={priority.indexOf(option) > -1} />
                                            {option}
                                        </li>
                                    )}
                                    getOptionLabel={(option) => option}
                                    multiple
                                    fullWidth
                                    onChange={(event, value) => setPriority(value)}
                                    renderInput={(params) => <TextField {...params} label={t("FormInputPriorityLabel", { ns: "bcf" })} />}
                                />
                            </FormControl>
                        </Grid>
                        <Grid item xs={12} sm={12} md={6}>
                            <FormControl fullWidth>
                                <Autocomplete
                                    id="status-select"
                                    disableCloseOnSelect
                                    options={extensions?.Status ?? []}
                                    renderOption={(props, option) => (
                                        <li {...props}>
                                            <Checkbox style={{ marginRight: 8 }} checked={status.indexOf(option) > -1} />
                                            {option}
                                        </li>
                                    )}
                                    getOptionLabel={(option) => option}
                                    multiple
                                    fullWidth
                                    onChange={(event, value) => setStatus(value)}
                                    renderInput={(params) => <TextField {...params} label={t("FormInputStatusLabel", { ns: "bcf" })} />}
                                />
                            </FormControl>
                        </Grid>
                        <Grid item xs={12} sm={12} md={6}>
                            <FormControl fullWidth>
                                <Autocomplete
                                    id="phase-select"
                                    disableCloseOnSelect
                                    options={extensions?.Stage ?? []}
                                    renderOption={(props, option) => (
                                        <li {...props}>
                                            <Checkbox style={{ marginRight: 8 }} checked={stage.indexOf(option) > -1} />
                                            {option}
                                        </li>
                                    )}
                                    getOptionLabel={(option) => option}
                                    multiple
                                    fullWidth
                                    onChange={(event, value) => setStage(value)}
                                    renderInput={(params) => <TextField {...params} label={t("FormInputPhaseLabel", { ns: "bcf" })} />}
                                />
                            </FormControl>
                        </Grid>
                        <Grid item xs={12} sm={12} md={6}>
                            <LocalizationProvider dateAdapter={AdapterDateFns} locale={localeMap[locale]}>
                                <DatePicker
                                    label={t("FormInputDueDateLabel", { ns: "bcf" })}
                                    mask={maskMap[locale]}
                                    value={dueDate}
                                    onChange={(newValue: Date) => setDueDate(newValue)}
                                    renderInput={(params: any) => (
                                        <>
                                            <RadioGroup
                                                aria-labelledby="demo-controlled-radio-buttons-group"
                                                name="controlled-radio-buttons-group"
                                                value={dueDateHandler}
                                                onChange={(event) => setDueDateHandler(event.target.value as "on" | "before" | "after")}
                                                sx={{ display: "flex", flexDirection: "row", flexWrap: "wrap" }}
                                            >
                                                <FormControlLabel
                                                    value="on"
                                                    control={<Radio />}
                                                    label={`${t("FormInputDueDateHandlerOnLabel", { ns: "bcf" })}`}
                                                />
                                                <FormControlLabel
                                                    value="before"
                                                    control={<Radio />}
                                                    label={`${t("FormInputDueDateHandlerBeforeLabel", { ns: "bcf" })}`}
                                                />
                                                <FormControlLabel
                                                    value="after"
                                                    control={<Radio />}
                                                    label={`${t("FormInputDueDateHandlerAfterLabel", { ns: "bcf" })}`}
                                                />
                                            </RadioGroup>
                                            <TextField {...params} fullWidth />
                                        </>
                                    )}
                                />
                            </LocalizationProvider>
                        </Grid>
                        <Grid item xs={12} sm={12}>
                            <FormControl fullWidth>
                                <Autocomplete
                                    id="labels-select"
                                    disableCloseOnSelect
                                    options={extensions?.Label ?? []}
                                    renderOption={(props, option) => (
                                        <li {...props}>
                                            <Checkbox style={{ marginRight: 8 }} checked={labels.indexOf(option) > -1} />
                                            {option}
                                        </li>
                                    )}
                                    getOptionLabel={(option) => option}
                                    multiple
                                    fullWidth
                                    onChange={(event, value) => setLabels(value)}
                                    renderInput={(params) => <TextField {...params} label={t("FormInputLabelsLabel", { ns: "bcf" })} />}
                                />
                            </FormControl>
                        </Grid>
                    </Grid>
                </CardContent>
                <CardActions sx={{ display: "flex", flex: 1 }}>
                    <Button sx={{ minHeight: "unset" }} onClick={() => onApplyFilter()}>
                        {t("ButtonFilterFormApplyLabel", { ns: "bcf" })}
                    </Button>
                    <Button sx={{ marginLeft: "8px", minHeight: "unset" }} variant="outlined" onClick={() => onClearFilter()}>
                        {t("ButtonFilterFormClearLabel", { ns: "bcf" })}
                    </Button>
                </CardActions>
            </FilterCard>
        </Collapse>
    );
};
