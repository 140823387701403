import { DetailedHTMLProps, ImgHTMLAttributes } from "react";
import { useParams } from "react-router-dom";
import bimData from "../../Assets/logo_bimdata.svg";
import idCapture from "../../Assets/logo_idcapture_white.svg";

const Logo = (props: DetailedHTMLProps<ImgHTMLAttributes<HTMLImageElement>, HTMLImageElement>) => {
    const params = useParams();

    switch (params.company) {
        case "id-capture":
            return <img src={idCapture} alt="ID Capture" {...props} />;
        default:
            return <img src={bimData} alt="BIMData" {...props} />;
    }
};

export default Logo;
