export class Version {
    private major: number;
    private minor: number;
    private revision: number;

    public get Major(): number {
        return this.major;
    }

    public get Minor(): number {
        return this.minor;
    }

    public get Revision(): number {
        return this.revision;
    }

    constructor(version: string) {
        if (!version.includes('"')) version = `"${version}"`;
        const splitVersion = JSON.parse(version).split(".");

        this.major = Number(splitVersion[0]);
        this.minor = Number(splitVersion[1]);
        this.revision = Number(splitVersion[2]);
    }

    public toString = (): string => `${this.major}.${this.minor}.${this.revision}`;
}
