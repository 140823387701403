import { useLayoutEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import {
    Alert,
    AlertTitle,
    Button,
    Collapse,
    FormControl,
    FormHelperText,
    Grid,
    IconButton,
    InputLabel,
    MenuItem,
    Select,
    TextField,
    Typography,
} from "@mui/material";
import { styled } from "@mui/material/styles";
import { usePluginHandler } from "../Contexts/PluginHandler/PluginHandlerContext";
import { FolderInfo } from "../Connectors/Models/FolderInfo";
import LoadingOverlay from "../Components/LoadingOverlay/LoadingOverlay";
import PageHeader from "../Components/PageHeader/PageHeader";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTimes } from "@fortawesome/pro-thin-svg-icons";
import { IPluginDataContext, usePluginData } from "../Contexts/PluginData/PluginDataContext";
import FolderSelect from "../Components/FolderSelect/FolderSelect";
import { useTranslation } from "react-i18next";

const Container = styled("div")(() => ({
    display: "flex",
    flexDirection: "column",
}));

const ContentContainer = styled("div")(() => ({
    margin: 10,
    display: "flex",
    flexDirection: "column",
    overflow: "auto",
}));

const MessageContainer = styled("div")(() => ({
    margin: "10px auto",
    textAlign: "center",
    "& > button": {
        marginTop: 50,
    },
}));

const ActionsContainer = styled("div")(() => ({
    display: "flex",
}));

interface IfcExportErrors {
    show: boolean;
    errors: Array<string>;
    message?: string;
}

const IfcExport = () => {
    const { plugin } = usePluginHandler();
    const { activeProject: project } = usePluginData() as IPluginDataContext;

    const [exportOptions, setExportOptions] = useState<Array<string>>([]);
    const [folderStructure, setFolderStructure] = useState<FolderInfo>();

    const [filename, setFilename] = useState<string>("");
    const [selectedExportOption, setSelectedExportOption] = useState<string>("");
    const [selectedFolder, setSelectedFolder] = useState<string>("");

    const [error, setError] = useState<IfcExportErrors>({ show: false, errors: [] });
    const [isPublishing, setIsPublishing] = useState<boolean>(false);
    const [isPublished, setIsPublished] = useState<boolean>(false);

    const navigate = useNavigate();
    const params = useParams();
    const { t } = useTranslation(["common", "ifc-export"]);

    useLayoutEffect(() => {
        if (!plugin || !project) return;

        plugin?.getIfcExportOptionsAsync().then(setExportOptions);

        plugin?.getDmsFolderStructureAsync(project.Cloud.Id, project.Id).then((folders) => {
            setFolderStructure(folders);
            setSelectedFolder(folders.Id.toString());
        });

        plugin?.getDocumentStatusAsync().then((documentStatus) => {
            if (!documentStatus?.ExportName) plugin?.getActiveDocumentAsync().then(setFilename);
            else setFilename(documentStatus.ExportName);
        });
    }, [plugin, project]);

    const navigateTo = (path: string) => {
        if (params.company) navigate(`/${params.company}${path}`);
        else navigate(path);
    };

    const onPublish = () => {
        if (!validateForm()) return;

        setIsPublishing(true);
        setTimeout(() => {
            plugin
                ?.publishIfcAsync({
                    cloudId: project!.Cloud.Id,
                    projectId: project!.Id,
                    exportName: filename.trim(),
                    exportOptions: selectedExportOption,
                    folderId: Number(selectedFolder),
                })
                .then((result) => {
                    setIsPublishing(false);
                    if (result) setError({ show: true, errors: [], message: result });
                    else setIsPublished(true);
                })
                .catch((err) => {
                    setIsPublishing(false);
                    setError({ show: true, errors: [], message: err });
                });
        }, 1000);
    };

    const validateForm = (): boolean => {
        const error: IfcExportErrors = { show: false, errors: [] };

        if (filename.trim().length === 0) error.errors.push("ifc-export-filename");
        if (selectedExportOption.trim().length === 0) error.errors.push("ifc-export-options");
        error.show = error.errors.length > 0;

        setError(error);

        return !error.show;
    };

    const hasError = (identifier: string) => {
        return error.errors.some((x) => x === identifier);
    };

    const clearErrors = (errorSource: string) => {
        const currError = { ...error };
        if (currError.errors) {
            currError.errors = [...currError.errors.filter((x) => x !== errorSource)];
        }

        if (currError.errors.length === 0) {
            currError.show = false;
        }

        setError(currError);
    };

    return (
        <Container>
            <PageHeader title={t("PageTitle", { ns: "ifc-export" })} />

            <Collapse in={error.show} sx={{ minHeight: "unset !important" }}>
                <Alert
                    severity="error"
                    action={
                        <IconButton
                            aria-label="close"
                            color="inherit"
                            size="small"
                            onClick={() => {
                                setError((x) => ({ ...x, show: false }));
                            }}
                        >
                            <FontAwesomeIcon icon={faTimes} />
                        </IconButton>
                    }
                    sx={{ marginTop: "10px" }}
                >
                    <AlertTitle>{t("ExportErrorTitle", { ns: "ifc-export" })}</AlertTitle>
                    {t("ExportErrorMessage", { ns: "ifc-export" })}
                    <br />
                    {error.message}
                </Alert>
            </Collapse>

            <ContentContainer>
                <LoadingOverlay active={isPublishing} />

                {isPublished ? (
                    <MessageContainer>
                        <Typography>{t("PublishSuccessMessage", { ns: "ifc-export", filename })}</Typography>
                        <Button onClick={() => navigateTo("/project")}>{t("ButtonOkLabel")}</Button>
                    </MessageContainer>
                ) : (
                    <Grid container spacing={2} sx={{ marginTop: "-6px" }}>
                        <Grid item xs={12} sm={6}>
                            <TextField
                                label={t("FormInputFilenameLabel", { ns: "ifc-export" })}
                                value={filename}
                                onChange={(e) => {
                                    setFilename(e.target.value);
                                    clearErrors("ifc-export-filename");
                                }}
                                error={hasError("ifc-export-filename")}
                                helperText={hasError("ifc-export-filename") && t("FormInputFilenameHelperText", { ns: "ifc-export" })}
                                required
                                fullWidth
                            />
                        </Grid>
                        <Grid item xs={12} sm={6}>
                            <FormControl error={hasError("ifc-export-options")} fullWidth>
                                <InputLabel id="ifc-export-options-label">
                                    {t("FormInputExportOptionsLabel", { ns: "ifc-export" })} *
                                </InputLabel>
                                <Select
                                    id="export-options-select"
                                    labelId="ifc-export-options-label"
                                    label={t("FormInputExportOptionsLabel", { ns: "ifc-export" })}
                                    value={selectedExportOption}
                                    onChange={(e) => {
                                        setSelectedExportOption(e.target.value as string);
                                        clearErrors("ifc-export-options");
                                    }}
                                    required
                                >
                                    {exportOptions.map((option, index) => (
                                        <MenuItem value={option} key={index}>
                                            {option}
                                        </MenuItem>
                                    ))}
                                </Select>
                                {hasError("ifc-export-options") && (
                                    <FormHelperText>{t("FormInputExportOptionsHelperText", { ns: "ifc-export" })}</FormHelperText>
                                )}
                            </FormControl>
                        </Grid>
                        <Grid item xs={12} sm={6}>
                            <FolderSelect
                                label={t("FormInputDestinationFolderLabel", { ns: "ifc-export" })}
                                value={selectedFolder}
                                onChange={(e) => setSelectedFolder(e.target.value as string)}
                                folders={folderStructure}
                            />
                        </Grid>
                        <Grid item sm={6} sx={{ height: 0, paddingLeft: "0 !important", paddingTop: "0 !important" }}></Grid>
                        <Grid item xs={12} sm={12} md={6}>
                            <ActionsContainer>
                                <Button fullWidth sx={{ marginRight: "10px" }} onClick={() => onPublish()}>
                                    {t("PublishButtonLabel", { ns: "ifc-export" })}
                                </Button>
                                <Button fullWidth sx={{ marginLeft: "10px" }} variant="outlined" onClick={() => navigateTo("/project")}>
                                    {t("CancelButtonLabel", { ns: "ifc-export" })}
                                </Button>
                            </ActionsContainer>
                        </Grid>
                    </Grid>
                )}
            </ContentContainer>
        </Container>
    );
};

export default IfcExport;
