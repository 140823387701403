import { useLayoutEffect } from "react";
import { Outlet } from "react-router-dom";
import { IPluginDataContext, usePluginData } from "../../Contexts/PluginData/PluginDataContext";
import { usePluginHandler } from "../../Contexts/PluginHandler/PluginHandlerContext";

const BaseDataContainer = () => {
    const { plugin } = usePluginHandler();
    const { setProjects, setActiveProject } = usePluginData() as IPluginDataContext;

    useLayoutEffect(() => {
        if (!plugin) return;

        Promise.all([plugin.getDocumentStatusAsync(), plugin.getProjectsAsync()]).then(([status, projects]) => {
            if (status?.CloudId && status.ProjectId) {
                const activeProject = projects.find((x) => x.Id === status?.ProjectId && x.Cloud.Id === status.CloudId);
                if (activeProject) setActiveProject(activeProject);
            }

            setProjects(projects);
        });

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [plugin]);

    return <Outlet />;
};

export default BaseDataContainer;
