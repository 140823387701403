import { createTheme, ThemeOptions } from "@mui/material";

const themeOptions: ThemeOptions = {
    palette: {
        // type: "light",
        primary: {
            main: "#2f374a",
            light: "#434e69",
            dark: "#1b202b",
        },
        secondary: {
            main: "#f9c72c",
            light: "#fad35e",
            dark: "#ebb607",
        },
        background: {
            default: "rgb(242 242 242)",
        },
        text: {
            primary: "#303545",
            secondary: "#757e9c",
        },
    },
    typography: {
        h1: {
            fontFamily: "Roboto, sans-serif",
            fontSize: "1.5rem",
        },
        h2: {
            fontFamily: "Roboto, sans-serif",
            fontSize: "1.125rem",
        },
        h3: {
            fontFamily: "Roboto, sans-serif",
            fontSize: "1rem",
        },
        h4: {
            fontFamily: "Roboto, sans-serif",
            fontSize: "0.875rem",
        },
        h5: {
            fontFamily: "Roboto, sans-serif",
            fontSize: "0.75rem",
        },
        body1: {
            fontFamily: "Roboto, sans-serif",
            color: "#606060",
        },
        body2: {
            fontFamily: "Roboto, sans-serif",
            color: "#606060",
        },
    },
    components: {
        MuiButton: {
            defaultProps: {
                variant: "contained",
                disableRipple: true,
            },
            styleOverrides: {
                root: {
                    textTransform: "none",
                    minHeight: "56px",
                    "&.Mui-disabled": {
                        color: "#FFFFFF",
                        backgroundColor: "#434e69",
                        opacity: 0.2,
                    },
                },
                containedPrimary: {
                    "&:hover": {
                        backgroundColor: "#434e69",
                    },
                },
            },
        },
        MuiCardActionArea: {
            defaultProps: {
                disableRipple: true,
            },
        },
        MuiIconButton: {
            defaultProps: {
                disableRipple: true,
            },
        },
        MuiMenuItem: {
            defaultProps: {
                disableRipple: true,
            },
        },
        MuiLink: {
            defaultProps: {
                underline: "hover",
            },
            styleOverrides: {
                root: {
                    fontFamily: "Roboto,sans-serif",
                    // fontSize: "inherit",
                    fontWeight: 600,
                    fontSize: "0.875rem",
                },
            },
        },
    },
};

export const theme = createTheme(themeOptions);
