import { Config } from "./Configuration";

const production: Config = {
    language: "en",
    sentry: {
        dsn: "https://fe1d6ce862a14d7383186305f40475a9@o399431.ingest.sentry.io/6414661",
        environment: "production",
        tracesSampleRate: 0.2,
    },
};

export default production;
