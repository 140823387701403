import { useEffect, useLayoutEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import {
    Accordion,
    AccordionDetails,
    AccordionSummary,
    Alert,
    AlertTitle,
    Autocomplete,
    AutocompleteChangeDetails,
    AutocompleteChangeReason,
    Button,
    Checkbox,
    Collapse,
    Divider,
    FormControl,
    FormHelperText,
    Grid,
    IconButton,
    InputAdornment,
    InputLabel,
    Link,
    MenuItem,
    Select,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    TextField,
    Typography,
} from "@mui/material";
import { styled } from "@mui/material/styles";
import { usePluginHandler } from "../Contexts/PluginHandler/PluginHandlerContext";
import { FolderInfo } from "../Connectors/Models/FolderInfo";
import LoadingOverlay from "../Components/LoadingOverlay/LoadingOverlay";
import { ExportTarget, ExportTargetType } from "../Connectors/Models/ExportTarget";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChevronDown, faSyncAlt, faTimes } from "@fortawesome/pro-thin-svg-icons";
import { ExportResult } from "../Connectors/Models/ExportResult";
import PageHeader from "../Components/PageHeader/PageHeader";
import { ExportConfiguration } from "../Connectors/Models/ExportConfiguration";
import { IPluginDataContext, usePluginData } from "../Contexts/PluginData/PluginDataContext";
import FolderSelect from "../Components/FolderSelect/FolderSelect";
import { useTranslation } from "react-i18next";

const Container = styled("div")(() => ({
    display: "flex",
    flexDirection: "column",
}));

const ContentContainer = styled("div")(() => ({
    margin: 10,
    display: "flex",
    flexDirection: "column",
}));

const MessageContainer = styled("div")(() => ({
    margin: "10px auto",
    textAlign: "center",
    "&> button": {
        marginTop: 50,
    },
}));

const ActionsContainer = styled("div")(() => ({
    display: "flex",
}));

interface FilenameMap {
    identifier: string;
    type: string;
    name: string;
    target: ExportTarget;
    filename: string;
}

interface ViewExportErrors {
    show: boolean;
    errors: Array<string>;
    message?: string;
}

const convertToValidFilename = (viewOrSheet: string): string => {
    return `${viewOrSheet.replace(/[/|\\:*?".<>]/g, "")}`;
};

const ViewsExport = () => {
    const { plugin } = usePluginHandler();
    const { activeProject: project } = usePluginData() as IPluginDataContext;

    const [canExportPdf, setCanExportPdf] = useState<boolean>(true);
    const [views, setViews] = useState<Array<ExportTarget>>([]);
    const [sheets, setSheets] = useState<Array<ExportTarget>>([]);
    const [exportOptions, setExportOptions] = useState<Array<ExportConfiguration>>([]);
    const [folderStructure, setFolderStructure] = useState<FolderInfo>();

    const [selectedElements, setSelectedElements] = useState<Array<string>>([]);
    const [exportTarget, setExportTarget] = useState<string>("0");
    const [selectedExportOption, setSelectedExportOption] = useState<string>("");
    const [selectedFolder, setSelectedFolder] = useState<string>("");
    const [filenameMap, setFilenameMap] = useState<Array<FilenameMap>>([]);

    const [error, setError] = useState<ViewExportErrors>({ show: false, errors: [] });
    const [isExporting, setIsExporting] = useState<boolean>(false);
    const [isExported, setIsExported] = useState<boolean>(false);
    const [exportResult, setExportResult] = useState<Array<ExportResult>>([]);

    const navigate = useNavigate();
    const params = useParams();
    const { t } = useTranslation(["common", "view-export"]);

    const ext = Number(exportTarget) === 0 ? "dwg" : "pdf";

    useLayoutEffect(() => {
        plugin?.get2DExportOptionsAsync().then(setExportOptions);
        plugin?.getDwgTargetsAsync().then(setViews);
        plugin?.getPdfTargetsAsync().then(setSheets);
        verifyExportPdf();

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [plugin]);

    useLayoutEffect(() => {
        if (!project) return;
        plugin?.getDmsFolderStructureAsync(project.Cloud.Id, project.Id).then((folders) => {
            setFolderStructure(folders);
            setSelectedFolder(folders.Id.toString());
        });

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [plugin, project]);

    useEffect(() => {
        if (!isExporting || !plugin) return;

        const targets: ExportTarget[] = filenameMap.map(
            (x) =>
                ({
                    ...x.target,
                    Name: x.filename,
                    Type: Number(exportTarget),
                    ExportOption: selectedExportOption ? selectedExportOption : null,
                } as ExportTarget)
        );

        if (plugin.isArchicad) {
            setTimeout(() => exportForArchicad(targets), 1000);
            return;
        }

        setTimeout(() => {
            plugin
                .export2DAsync({
                    cloudId: project!.Cloud.Id,
                    projectId: project!.Id,
                    folderId: Number(selectedFolder),
                    exportTargets: targets,
                })
                .then((x) => {
                    setIsExporting(false);
                    setIsExported(true);
                    setExportResult(x);
                });
        }, 1000);

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isExporting]);

    const navigateTo = (path: string) => {
        if (params.company) navigate(`/${params.company}${path}`);
        else navigate(path);
    };

    const exportForArchicad = (targets: ExportTarget[]) => {
        if (!plugin) return;

        const check = new Promise<boolean>((resolve, reject) => {
            plugin
                .checkReadinessAsync({
                    cloudId: project!.Cloud.Id,
                    projectId: project!.Id,
                    folderId: Number(selectedFolder),
                    exportTargets: targets,
                })
                .then((canExport) => {
                    if (!canExport) reject();
                    else resolve(true);
                });
        });

        check
            .then((x) => {
                plugin
                    .export2DAsync({
                        cloudId: project!.Cloud.Id,
                        projectId: project!.Id,
                        folderId: Number(selectedFolder),
                        exportTargets: targets,
                    })
                    .then((x) => {
                        setIsExporting(false);
                        setIsExported(true);
                        setExportResult(x);
                    });
            })
            .catch(() => {
                setTimeout(() => exportForArchicad(targets), 2000);
            });
    };

    const verifyExportPdf = () => {
        if (!plugin) return;
        plugin.canExportToPdfAsync().then(setCanExportPdf);
    };

    const onExport = () => {
        if (validateForm()) setIsExporting(true);
    };

    const getFullname = (target: ExportTarget): string => {
        if (target.Type === ExportTargetType.dwg) {
            return `${t("FilenameMappingTableFilenamePrefixView", { ns: "views-export" })}${
                target.TypeName ? ` (${target.TypeName})` : ""
            }: ${target.Name}`.trim();
        } else {
            return `${t("FilenameMappingTableFilenamePrefixSheet", { ns: "views-export" })}${
                target.TypeName ? ` (${target.TypeName})` : ""
            }: ${target.Name}`.trim();
        }
    };

    const getTarget = (identifier: string): ExportTarget | undefined => {
        const [typeId, elementId] = identifier.split(":::");
        const collection = Number(typeId) === 0 ? views : sheets;

        return collection.find((x) => (plugin!.isArchicad ? x.ArchicadTargetId === elementId : x.RevitElementId === Number(elementId)));
    };

    const insertFilenameMap = (identifier: string) => {
        const target = getTarget(identifier);
        if (!target) return;

        const name = getFullname(target);
        const type = target.TypeName ?? "";
        const filename = convertToValidFilename(name);
        const currentMap = [...filenameMap];
        currentMap.push({ identifier, type, name, target, filename });
        setFilenameMap(currentMap);
    };

    const updateFilenameMap = (identifier: string, filename: string) => {
        const currentMap = [...filenameMap];
        const map = currentMap.find((x) => x.identifier === identifier);
        if (!map) return;

        map.filename = filename;
        setFilenameMap(currentMap);
    };

    const removeFilenameMap = (identifier: string, partial: boolean = false) => {
        let filter = (x: FilenameMap) => x.identifier !== identifier;
        if (partial) {
            filter = (x: FilenameMap) => !x.identifier.startsWith(identifier);
        }

        const currentMap = filenameMap.filter(filter);
        setFilenameMap(currentMap);
    };

    const validateForm = (): boolean => {
        if (!plugin) return false;

        const error: ViewExportErrors = { show: false, errors: [] };

        if (selectedElements.length === 0) error.errors.push("view-export-selection");
        if (selectedExportOption.length === 0 && Number(exportTarget) === ExportTargetType.dwg && plugin.isArchicad)
            error.errors.push("view-export-options");
            
        for (let i = 0, len = filenameMap.length; i < len; i++) {
            const entry = filenameMap[i];
            if (entry.filename.trim().length === 0) error.errors.push(`view-export-filename-${entry.identifier}`);
        }

        error.show = error.errors.length > 0;

        setError(error);

        return !error.show;
    };

    const hasError = (identifier: string) => {
        return error.errors.some((x) => x === identifier);
    };

    const clearErrors = (errorSource: string) => {
        const currError = { ...error };
        if (currError.errors) {
            currError.errors = [...currError.errors.filter((x) => x !== errorSource)];
        }

        if (currError.errors.length === 0) {
            currError.show = false;
        }

        setError(currError);
    };

    const onViewOrSheetSelectionChange = (
        exportTargetType: ExportTargetType,
        value: ExportTarget[],
        reason: AutocompleteChangeReason,
        details?: AutocompleteChangeDetails<ExportTarget> | undefined
    ) => {
        switch (reason) {
            case "selectOption":
                insertFilenameMap(
                    `${details?.option.Type}:::${plugin!.isArchicad ? details?.option.ArchicadTargetId : details?.option.RevitElementId}`
                );
                break;
            case "removeOption":
                removeFilenameMap(
                    `${details?.option.Type}:::${plugin!.isArchicad ? details?.option.ArchicadTargetId : details?.option.RevitElementId}`
                );
                break;
            case "clear":
                removeFilenameMap(`${exportTargetType}:::`, true);
                break;
        }

        clearErrors("view-export-selection");
        setSelectedElements((selection) =>
            [
                ...selection.filter((x) => !x.startsWith(exportTargetType.toString())),
                ...value.map((x) => `${x.Type}:::${plugin!.isArchicad ? x.ArchicadTargetId : x.RevitElementId}`),
            ].filter((value, index, self) => self.indexOf(value) === index)
        );
    };

    return (
        <Container>
            <PageHeader title={"Export 2D"} />

            <Collapse in={!canExportPdf} sx={{ minHeight: "unset !important" }}>
                <Alert
                    sx={{ marginTop: "10px" }}
                    severity="warning"
                    action={
                        <IconButton aria-label="close" color="inherit" size="small" onClick={() => verifyExportPdf()}>
                            <FontAwesomeIcon icon={faSyncAlt} />
                        </IconButton>
                    }
                >
                    <AlertTitle>{t("PdfPrinterWarningTitle", { ns: "views-export" })}</AlertTitle>
                    {t("PdfPrinterWarningMessagePartOne", { ns: "views-export" })}{" "}
                    <Link href="#">{t("PdfPrinterWarningDownloadLink", { ns: "views-export" })}</Link>
                    {t("PdfPrinterWarningMessagePartTwo", { ns: "views-export" })}
                </Alert>
            </Collapse>

            <Collapse in={error.show} sx={{ minHeight: "unset !important" }}>
                <Alert
                    sx={{ marginTop: "10px" }}
                    severity="error"
                    action={
                        <IconButton
                            aria-label="close"
                            color="inherit"
                            size="small"
                            onClick={() => {
                                setError((x) => ({ ...x, show: false }));
                            }}
                        >
                            <FontAwesomeIcon icon={faTimes} />
                        </IconButton>
                    }
                >
                    <AlertTitle>{t("ExportErrorTitle", { ns: "views-export" })}</AlertTitle>
                    {t("ExportErrorMessage", { ns: "views-export" })}
                    <br />
                    {error.message}
                </Alert>
            </Collapse>

            <ContentContainer>
                <LoadingOverlay active={isExporting} />

                {isExported ? (
                    <MessageContainer>
                        {exportResult.some((x) => x.Done) && (
                            <>
                                <Typography>
                                    {t("ExportSuccessMessage", {
                                        ns: "views-export",
                                        count: exportResult.filter((x) => x.Done).length,
                                    })}
                                </Typography>
                                {exportResult
                                    .filter((x) => x.Done)
                                    .map((file, index) => (
                                        <Typography key={index}>{file.Target.Name}</Typography>
                                    ))}

                                {exportResult.some((x) => !x.Done) && <Divider sx={{ margin: "30px 0" }} />}
                            </>
                        )}

                        {exportResult.some((x) => !x.Done) && (
                            <>
                                <Typography>
                                    {t("ExportFailedMessage", {
                                        ns: "views-export",
                                        count: exportResult.filter((x) => !x.Done).length,
                                    })}
                                </Typography>
                                {exportResult
                                    .filter((x) => !x.Done)
                                    .map((file, index) => (
                                        <Typography key={index}>{file.Target.Name}</Typography>
                                    ))}
                            </>
                        )}
                        <Button onClick={() => navigateTo("/project")}>{t("ButtonOkLabel")}</Button>
                    </MessageContainer>
                ) : (
                    <Grid container spacing={2} sx={{ overflow: "auto", marginTop: "-6px" }}>
                        <Grid item xs={12} sm={6}>
                            <FormControl error={hasError("view-export-selection")} fullWidth>
                                <Autocomplete
                                    id="view-export-selected-views"
                                    disableCloseOnSelect
                                    options={views
                                        .filter((x) => x.TypeName !== "DrawingSheet")
                                        .sort(
                                            (a: ExportTarget, b: ExportTarget) =>
                                                -b.TypeName!.localeCompare(a.TypeName!) || -b.Name.localeCompare(a.Name)
                                        )}
                                    groupBy={(option) =>
                                        plugin!.isArchicad
                                            ? option.TypeName!
                                            : t(`FormInputViewsGrouping${option.TypeName!}`, { ns: "views-export" })
                                    }
                                    renderOption={(props, option) => (
                                        <li {...props}>
                                            <Checkbox
                                                style={{ marginRight: 8 }}
                                                checked={
                                                    selectedElements.indexOf(
                                                        `${option.Type}:::${
                                                            plugin!.isArchicad ? option.ArchicadTargetId : option.RevitElementId
                                                        }`
                                                    ) > -1
                                                }
                                            />
                                            {option.Name}
                                        </li>
                                    )}
                                    getOptionLabel={(option) => option.Name}
                                    multiple
                                    fullWidth
                                    onChange={(event, reason, value, details) =>
                                        onViewOrSheetSelectionChange(ExportTargetType.dwg, reason, value, details)
                                    }
                                    renderInput={(params) => (
                                        <TextField
                                            {...params}
                                            label={`${t("FormInputViewsLabel", { ns: "views-export" })} *`}
                                            error={hasError("view-export-selection")}
                                            placeholder={t("InputPlaceholderSearch")}
                                        />
                                    )}
                                />
                            </FormControl>
                        </Grid>
                        <Grid item xs={12} sm={6}>
                            <FormControl error={hasError("view-export-selection")} fullWidth>
                                <Autocomplete
                                    id="view-export-selected-sheets"
                                    disableCloseOnSelect
                                    options={sheets.sort((a: ExportTarget, b: ExportTarget) => -b.Name.localeCompare(a.Name))}
                                    renderOption={(props, option) => (
                                        <li {...props}>
                                            <Checkbox
                                                style={{ marginRight: 8 }}
                                                checked={
                                                    selectedElements.indexOf(
                                                        `${option.Type}:::${
                                                            plugin!.isArchicad ? option.ArchicadTargetId : option.RevitElementId
                                                        }`
                                                    ) > -1
                                                }
                                            />
                                            {option.Name}
                                        </li>
                                    )}
                                    getOptionLabel={(option) => option.Name}
                                    multiple
                                    fullWidth
                                    onChange={(event, reason, value, details) =>
                                        onViewOrSheetSelectionChange(ExportTargetType.pdf, reason, value, details)
                                    }
                                    renderInput={(params) => (
                                        <TextField
                                            {...params}
                                            label={`${t("FormInputSheetsLabel", { ns: "views-export" })} *`}
                                            error={hasError("view-export-selection")}
                                            placeholder={t("InputPlaceholderSearch")}
                                        />
                                    )}
                                />
                                {hasError("view-export-selection") && (
                                    <FormHelperText>{t("FormInputViewsSheetsHelperText", { ns: "views-export" })}</FormHelperText>
                                )}
                            </FormControl>
                        </Grid>
                        <Grid item xs={12} sm={6}>
                            <FormControl fullWidth>
                                <InputLabel id="export-target-label">{t("FormInputExportTargetLabel", { ns: "views-export" })}</InputLabel>
                                <Select
                                    id="target-select"
                                    labelId="export-target-label"
                                    label={t("FormInputExportTargetLabel", { ns: "views-export" })}
                                    value={exportTarget}
                                    onChange={(e) => {
                                        setExportTarget(e.target.value as string);
                                        setSelectedExportOption("");
                                    }}
                                    required
                                >
                                    <MenuItem value={ExportTargetType.dwg}>DWG</MenuItem>
                                    <MenuItem value={ExportTargetType.pdf} disabled={!canExportPdf}>
                                        PDF
                                    </MenuItem>
                                </Select>
                            </FormControl>
                        </Grid>
                        <Grid item xs={12} sm={6}>
                            <FormControl fullWidth error={hasError("view-export-options")}>
                                <InputLabel id="2d-export-options-label">
                                    {t("FormInputExportOptionsLabel", { ns: "views-export" })}
                                    {plugin?.isArchicad && Number(exportTarget) === ExportTargetType.dwg ? " *" : ""}
                                </InputLabel>
                                <Select
                                    id="2d-options-select"
                                    labelId="2d-export-options-label"
                                    label={`${t("FormInputExportOptionsLabel", {
                                        ns: "views-export",
                                    })}${plugin?.isArchicad && Number(exportTarget) === ExportTargetType.dwg ? " *" : ""}`}
                                    value={selectedExportOption}
                                    onChange={(e) => {
                                        setSelectedExportOption(e.target.value as string);
                                        clearErrors("view-export-options");
                                    }}
                                    required={plugin?.isArchicad === true}
                                    disabled={Number(exportTarget) === ExportTargetType.pdf}
                                >
                                    {!exportOptions || exportOptions.length === 0 ? (
                                        <MenuItem value={""}>{t("DropdownOptionNone")}</MenuItem>
                                    ) : null}
                                    {exportOptions.map((option, index) => (
                                        <MenuItem value={option.Name} key={index}>
                                            {option.Name}
                                        </MenuItem>
                                    ))}
                                </Select>
                                {hasError("view-export-options") && (
                                    <FormHelperText>{t("FormInputViewsExportOptionsHelperText", { ns: "views-export" })}</FormHelperText>
                                )}
                            </FormControl>
                        </Grid>
                        <Grid item xs={12} sm={6}>
                            <FolderSelect
                                label={t("FormInputDestinationFolderLabel", { ns: "views-export" })}
                                value={selectedFolder}
                                onChange={(e) => setSelectedFolder(e.target.value as string)}
                                folders={folderStructure}
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <Accordion>
                                <AccordionSummary expandIcon={<FontAwesomeIcon icon={faChevronDown} />}>
                                    <Typography variant="button">{t("FilenameMappingTableTitle", { ns: "views-export" })}</Typography>
                                </AccordionSummary>
                                <AccordionDetails>
                                    {filenameMap.length === 0 ? (
                                        <Typography>{t("FilenameMappingTableNoSelection", { ns: "views-export" })}</Typography>
                                    ) : (
                                        <TableContainer>
                                            <Table>
                                                <TableHead>
                                                    <TableRow>
                                                        <TableCell width={"40%"}>
                                                            {t("FilenameMappingTableElementTitle", { ns: "views-export" })}
                                                        </TableCell>
                                                        <TableCell width={"60%"}>
                                                            {t("FilenameMappingTableFilenameTitle", { ns: "views-export" })}
                                                        </TableCell>
                                                    </TableRow>
                                                </TableHead>
                                                <TableBody>
                                                    {filenameMap.map((value) => {
                                                        return (
                                                            <TableRow
                                                                key={value.identifier}
                                                                sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                                                            >
                                                                <TableCell component="th" scope="row">
                                                                    {value.name}
                                                                </TableCell>
                                                                <TableCell>
                                                                    {Number(exportTarget) === 0 || Number(exportTarget) === 1 ? (
                                                                        <TextField
                                                                            fullWidth
                                                                            value={value.filename}
                                                                            onChange={(e) => {
                                                                                updateFilenameMap(
                                                                                    value.identifier,
                                                                                    e.target.value as string
                                                                                );
                                                                                clearErrors(`view-export-filename-${value.identifier}`);
                                                                            }}
                                                                            InputProps={{
                                                                                endAdornment: (
                                                                                    <InputAdornment position="end">.{ext}</InputAdornment>
                                                                                ),
                                                                            }}
                                                                            error={hasError(`view-export-filename-${value.identifier}`)}
                                                                            helperText={
                                                                                hasError(`view-export-filename-${value.identifier}`) &&
                                                                                t("FilenameMappingTableFilenameHelperText", {
                                                                                    ns: "views-export",
                                                                                })
                                                                            }
                                                                            required
                                                                        />
                                                                    ) : null}
                                                                </TableCell>
                                                            </TableRow>
                                                        );
                                                    })}
                                                </TableBody>
                                            </Table>
                                        </TableContainer>
                                    )}
                                </AccordionDetails>
                            </Accordion>
                        </Grid>
                        <Grid item xs={12} sm={12} md={6}>
                            <ActionsContainer>
                                <Button fullWidth sx={{ marginRight: "10px" }} onClick={() => onExport()}>
                                    {t("ButtonExportLabel", { ns: "views-export" })}
                                </Button>
                                <Button fullWidth sx={{ marginLeft: "10px" }} variant="outlined" onClick={() => navigateTo("/project")}>
                                    {t("ButtonCancelLabel", { ns: "views-export" })}
                                </Button>
                            </ActionsContainer>
                        </Grid>
                    </Grid>
                )}
            </ContentContainer>
        </Container>
    );
};

export default ViewsExport;
