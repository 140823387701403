import { Params } from "react-router-dom";

const getCompanySpecificTranslation = (translationKey: string, params: Readonly<Params<string>>): string => {
    let key;
    switch (params.company) {
        case "id-capture":
            key = "id-capture";
            break;

        default:
            key = "default";
            break;
    }

    return `${translationKey}.${key}`;
};

export default getCompanySpecificTranslation;
