import parseISO from "date-fns/parseISO";
import { ArchicadHandler } from "../Archicad/ArchicadHandler";
import { BcfComment } from "../Models/BcfComment";
import { BcfExtensions } from "../Models/BcfExtensions";
import { BcfFullTopic } from "../Models/BcfFullTopic";
import { BcfTopic } from "../Models/BcfTopic";
import { BcfViewpoint } from "../Models/BcfViewpoint";
import { CloudInfo } from "../Models/CloudInfo";
import { DocumentStatus } from "../Models/DocumentStatus";
import { ExportConfiguration } from "../Models/ExportConfiguration";
import { ExportOptions } from "../Models/ExportOptions";
import { ExportResult } from "../Models/ExportResult";
import { ExportTarget } from "../Models/ExportTarget";
import { FolderInfo } from "../Models/FolderInfo";
import { IfcPublishOptions } from "../Models/IfcPublishOptions";
import { ISynchronisationElement } from "../Models/ISynchronisationElement";
import { PluginDocumentStatus } from "../Models/PluginDocumentStatus";
import { ProjectInfo } from "../Models/ProjectInfo";
import { UserInfo } from "../Models/UserInfo";
import { Version } from "../Models/Version";
import { RevitHandler } from "../Revit/RevitHandler";
import { IHandler } from "./IHandler";

const CefSharp = window.CefSharp;

export class PluginHandler {
    private handler!: IHandler;

    public isArchicad: Boolean;
    public isRevit: Boolean;

    private constructor(handler: IHandler, isArchicad: boolean, isRevit: boolean) {
        this.handler = handler;
        this.isArchicad = isArchicad;
        this.isRevit = isRevit;
    }

    static async create(): Promise<PluginHandler> {
        if (CefSharp && CefSharp.BindObjectAsync) {
            await CefSharp.BindObjectAsync({ IgnoreCache: true });
        }

        let handler: IHandler;
        let isRevit: boolean = false;
        let isArchicad: boolean = false;

        if (window.revit) {
            handler = new RevitHandler();
            isRevit = true;
        } else if (window.archicad) {
            handler = new ArchicadHandler();
            isArchicad = true;
        } else throw new Error("No valid Revit or Archicad plugin detected.");

        return new PluginHandler(handler, isArchicad, isRevit);
    }

    //
    // Plugin
    //

    async getPluginVersionAsync(): Promise<Version> {
        const result = await this.handler.getPluginVersion();

        return new Version(result);
    }

    async getActiveDocumentAsync(): Promise<string> {
        let result = await this.handler.getActiveDocument();
        if (!result.includes('"')) result = `"${result}"`;
        if (!result.includes("\\\\")) result = result.split("\\").join("\\\\");

        const path = JSON.parse(result) as string;
        const parts = path.split("\\");
        const filename = parts[parts.length - 1].replace("rvt", "ifc").replace("pln", "ifc");

        return filename;
    }

    async openBrowserAsync(url: string): Promise<void> {
        this.handler.openBrowser(url);
    }

    async openDialogAsync(identifier: string, url: string, height: number, width: number): Promise<void> {
        this.handler.openWindow(identifier, url, height, width);
    }

    async closeDialogAsync(identifier: string): Promise<void> {
        this.handler.closeWindow(identifier);
    }

    //
    // User
    //

    async getTokenAsync(): Promise<boolean> {
        const result = await this.handler.getToken();

        return result;
    }

    async getUserInfoAsync(): Promise<UserInfo> {
        const result = await this.handler.getUserInfo();
        const user = JSON.parse(result) as UserInfo;

        return user;
    }

    //
    // Project
    //

    async getWorkspacesAsync(): Promise<Array<CloudInfo>> {
        const result = await this.handler.getWorkspaces();
        const workspaces = JSON.parse(result) as Array<CloudInfo>;

        return workspaces;
    }

    async getProjectsAsync(): Promise<Array<ProjectInfo>> {
        const result = await this.handler.getProjects();
        const projects = JSON.parse(result) as Array<ProjectInfo>;

        return projects;
    }

    async getDocumentStatusAsync(): Promise<DocumentStatus | null> {
        const result = await this.handler.getDocumentStatus();
        const status = JSON.parse(result) as PluginDocumentStatus;
        if (!status) return null;

        return {
            CloudId: Number(status.CloudId),
            ProjectId: Number(status.ProjectId),
            DocumentId: Number(status.DocumentId),
            IfcDocumentId: status.IfcDocumentId,
            ExportName: status.ExportName,
            ExportFolderId: Number(status.ExportFolderId),
            ExportOptions: status.ExportOptions,
            LastSynchronizationDate: status.LastSynchronizationDate,
            LastExportDate: status.LastExportDate,
        } as DocumentStatus;
    }

    async setActiveProjectAsync(cloudId: number, projectId: number): Promise<DocumentStatus> {
        const result = await this.handler.setActiveProject(cloudId, projectId);
        const status = JSON.parse(result) as DocumentStatus;

        return status;
    }

    //
    // DMS
    //

    async getDmsFolderStructureAsync(cloudId: number, projectId: number): Promise<FolderInfo> {
        const result = await this.handler.getDmsFolderStructure(cloudId, projectId);
        const folderStructure = JSON.parse(result) as FolderInfo;

        return folderStructure;
    }

    //
    // Publish IFC
    //

    async getIfcExportOptionsAsync(): Promise<Array<string>> {
        const result = await this.handler.getAvailableIfcExportOptions();
        const options = JSON.parse(result) as Array<string>;

        return options;
    }

    async publishIfcAsync(options: IfcPublishOptions): Promise<string> {
        const result = await this.handler.publishIfc(options);
        const message = JSON.parse(result) as string;

        return message;
    }

    //
    // Export 2D (Views or sheets)
    //

    async canExportToPdfAsync(): Promise<boolean> {
        const result = await this.handler.verifyPrinter2D();
        const canExport = JSON.parse(result) as boolean;

        return canExport;
    }

    async getDwgTargetsAsync(): Promise<Array<ExportTarget>> {
        const result = await this.handler.getDwgTargets();
        const targets = JSON.parse(result) as Array<ExportTarget>;

        return targets;
    }

    async getPdfTargetsAsync(): Promise<Array<ExportTarget>> {
        const result = await this.handler.getPdfTargets();
        const targets = JSON.parse(result) as Array<ExportTarget>;

        return targets;
    }

    async get2DExportOptionsAsync(): Promise<Array<ExportConfiguration>> {
        const result = await this.handler.getExport2DOptions();
        const options = JSON.parse(result) as Array<ExportConfiguration>;

        return options;
    }

    async checkReadinessAsync(options: ExportOptions): Promise<boolean> {
        const result = await this.handler.checkReadiness(options);

        return result;
    }

    async export2DAsync(options: ExportOptions): Promise<Array<ExportResult>> {
        const result = await this.handler.export2D(options);
        const details = JSON.parse(result) as Array<ExportResult>;

        return details;
    }

    //
    // Synchronisation
    //

    // getSynchronizationState: () => Promise<string>;
    async getSynchronisationStateAsync(
        cloudId: number,
        projectId: number,
        ifcId: number,
        translator: string,
    ): Promise<Array<ISynchronisationElement>> {
        const result = await this.handler.getSynchronizationState(cloudId, projectId, ifcId, translator);
        const state = JSON.parse(result) as Array<ISynchronisationElement>;

        return state.map((x) => ({ ...x, Type: this.isRevit ? "REVIT" : "ARCHICAD" }));
    }

    async synchroniseAsync(
        cloudId: number,
        projectId: number,
        ifcId: number,
        elements: Array<ISynchronisationElement>,
        importTranslator: string,
    ): Promise<Array<ISynchronisationElement>> {
        const result = await this.handler.synchronize(cloudId, projectId, ifcId, elements, importTranslator);
        const state = JSON.parse(result) as Array<ISynchronisationElement>;

        return state.map((x) => ({ ...x, Type: this.isRevit ? "REVIT" : "ARCHICAD" }));
    }

    async getImportTranslators(): Promise<Array<string>> {
        if (this.isRevit) return [];

        const result = await this.handler.getImportTranslators();
        let translators: Array<string> = [];

        try {
            translators = JSON.parse(result);
        } catch (e) {}

        return translators;
    }

    //
    // BCF
    //

    async getBcfExtensionsAsync(cloudId: number, projectId: number): Promise<BcfExtensions> {
        const result = await this.handler.getBcfExtensions(cloudId, projectId);
        const extensions = JSON.parse(result) as BcfExtensions;

        return extensions;
    }

    async getBcfTopicsAsync(cloudId: number, projectId: number): Promise<Array<BcfTopic>> {
        const result = await this.handler.getBcfTopics(cloudId, projectId);
        const topics = JSON.parse(result) as Array<BcfTopic>;

        return topics;
    }

    async getBcfTopicAsync(cloudId: number, projectId: number, topicGuid: string): Promise<BcfFullTopic> {
        const result = await this.handler.getBcfTopic(cloudId, projectId, topicGuid);
        const topic = JSON.parse(result) as BcfFullTopic;
        if (topic.dueDate !== null) {
            topic.dueDate = parseISO(topic.dueDate as unknown as string);
        }

        return topic;
    }

    async createBcfTopicAsync(cloudId: number, projectId: number, topic: BcfFullTopic): Promise<BcfFullTopic> {
        const result = await this.handler.createNewTopic(cloudId, projectId, topic);
        const newTopic = JSON.parse(result) as BcfFullTopic;

        return newTopic;
    }

    async updateBcfTopicAsync(cloudId: number, projectId: number, topic: BcfFullTopic): Promise<BcfFullTopic> {
        const result = await this.handler.updateTopic(cloudId, projectId, topic);
        const updatedTopic = JSON.parse(result) as BcfFullTopic;

        return updatedTopic;
    }

    async extractViewpointAsync(): Promise<BcfViewpoint> {
        const result = await this.handler.extractViewpoint();
        const viewpoint = JSON.parse(result) as BcfViewpoint;

        return viewpoint;
    }

    async showViewpointAsync(viewpoint: BcfViewpoint): Promise<void> {
        await this.handler.showViewpoint(viewpoint);
    }

    async removeBcfTopicAsync(cloudId: number, projectId: number, topicGuid: string): Promise<void> {
        await this.handler.removeTopic(cloudId, projectId, topicGuid);
    }

    async addBcfTopicCommentAsync(
        cloudId: number,
        projectId: number,
        topicGuid: string,
        comment: BcfComment,
    ): Promise<BcfComment> {
        const result = await this.handler.addComment(cloudId, projectId, topicGuid, comment);
        const createdComment = JSON.parse(result) as BcfComment;

        return createdComment;
    }

    // updateComment: () => Promise<string>;
    // removeComment: () => Promise<string>;

    //
    // Revit
    //

    async highlightElementAsync(elementId: number | string): Promise<void> {
        await this.handler.highlightElement(elementId);
    }
}
