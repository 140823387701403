import { StrictMode } from "react";
import { createRoot } from "react-dom/client";
import { RouterProvider } from "react-router-dom";
import * as Sentry from "@sentry/react";
import { configuration } from "./_Configuration/Configuration";
import router from "./browserRouter";

import "./Localisation/config";

if (configuration.sentry) {
    Sentry.init({
        dsn: configuration.sentry.dsn,
        integrations: [Sentry.browserTracingIntegration()],
        tracesSampleRate: configuration.sentry.tracesSampleRate,
        environment: configuration.sentry.environment,
    });
}

const container = document.getElementById("root")!;
const root = createRoot(container);

root.render(
    <StrictMode>
        <RouterProvider router={router} />
    </StrictMode>,
);
