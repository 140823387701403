import { MouseEvent, useLayoutEffect, useMemo, useRef, useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { CardContent, CardMedia, IconButton, MobileStepper } from "@mui/material";
import { faChevronLeft, faChevronRight } from "@fortawesome/pro-thin-svg-icons";
import { ProjectInfo } from "../../Connectors/Models/ProjectInfo";

import defaultImagePreview from "../../Assets/default-model-preview.png";
import { useParams } from "react-router-dom";

export interface IProjectImagesProps {
    project: ProjectInfo;
}

const baseWidth = 205;
const imageSlices = 15;

const ProjectImages = ({ project }: IProjectImagesProps) => {
    const params = useParams();

    const [activeStep, setActiveStep] = useState(0);
    const [translation, setTranslation] = useState<number>(0);
    const containerRef = useRef<HTMLDivElement>(null);
    const viewportRef = useRef<HTMLImageElement>(null);
    const maxSteps = useMemo(() => project.Images.length, [project.Images]);

    useLayoutEffect(() => {
        setActiveStep(0);

        if (isCompanyOverride() && project.Images.length === 0) {
            addCompanyDefaultImage();
        }

        if (isCompanyOverride()) return;

        if (containerRef.current) {
            const containerRect = containerRef.current.getBoundingClientRect();
            setTranslation((baseWidth - containerRect.width) / 2);
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [project]);

    const isCompanyOverride = () => {
        switch (params.company) {
            case "id-capture":
                return true;
            default:
                return false;
        }
    };

    const addCompanyDefaultImage = () => {
        switch (params.company) {
            case "id-capture":
                project.Images.push("/logo_idcapture.svg");
                break;
            default:
                break;
        }
    };

    const getImageHeight = () => {
        switch (params.company) {
            case "id-capture":
                return "165px";
            default:
                return "205px";
        }
    };

    const handleMouseMove = (event: MouseEvent<HTMLDivElement>) => {
        if (isCompanyOverride()) return;

        if (containerRef.current && viewportRef.current) {
            const containerRect = containerRef.current.getBoundingClientRect();
            const diff = baseWidth - containerRect.width;
            let offset = Math.abs(
                Math.ceil(imageSlices * (1 - (event.clientX - containerRect.x) / containerRect.width)),
            );
            offset = Math.min(offset, imageSlices);

            setTranslation((offset - 1) * (containerRect.width + diff) + diff / 2);
        }
    };

    const handleNext = () => {
        setActiveStep((prevActiveStep) => prevActiveStep + 1);
    };

    const handleBack = () => {
        setActiveStep((prevActiveStep) => prevActiveStep - 1);
    };

    return (
        <CardContent
            sx={{ margin: "0 auto", padding: 0, maxWidth: "205px", overflow: "hidden" }}
            ref={containerRef}
            onMouseMove={(e) => handleMouseMove(e)}
        >
            <CardMedia
                sx={
                    isCompanyOverride()
                        ? { height: "205px", display: "flex", alignItems: "center", justifyContent: "center" }
                        : {}
                }
            >
                <img
                    src={project.Images[activeStep] ?? defaultImagePreview}
                    alt="Preview"
                    height={getImageHeight()}
                    style={{ transform: `translateX(-${translation}px)` }}
                    ref={viewportRef}
                />
            </CardMedia>

            {maxSteps > 1 && (
                <MobileStepper
                    variant="text"
                    steps={maxSteps}
                    position="static"
                    activeStep={activeStep}
                    nextButton={
                        <IconButton
                            size="small"
                            onClick={handleNext}
                            disabled={activeStep === maxSteps - 1 || maxSteps === 0}
                            sx={{ minHeight: "unset", fontSize: 12, width: 22, height: 22 }}
                        >
                            <FontAwesomeIcon icon={faChevronRight} />
                        </IconButton>
                    }
                    backButton={
                        <IconButton
                            size="small"
                            onClick={handleBack}
                            disabled={activeStep === 0}
                            sx={{ minHeight: "unset", fontSize: 12, width: 22, height: 22 }}
                        >
                            <FontAwesomeIcon icon={faChevronLeft} />
                        </IconButton>
                    }
                    sx={{ fontSize: 12, background: "#00000020", position: "absolute", top: 0, left: 0, right: 0 }}
                    onClick={(e) => e.stopPropagation()}
                />
            )}
        </CardContent>
    );
};

export default ProjectImages;
