import { createContext, useContext, useState } from "react";

import { ProjectInfo } from "../../Connectors/Models/ProjectInfo";

export interface IPluginDataContext {
    projects: Array<ProjectInfo>;
    activeProject?: ProjectInfo;
    setProjects: (projects: Array<ProjectInfo>) => void;
    setActiveProject: (project: ProjectInfo) => void;
}

const PluginDataContext = createContext<IPluginDataContext | null>(null);

export const usePluginData = () => useContext(PluginDataContext);

interface IPluginDataProviderProps {
    children: React.ReactNode;
}

const PluginDataProvider = ({ children }: IPluginDataProviderProps) => {
    const [projects, setProjects] = useState<Array<ProjectInfo>>([]);
    const [activeProject, setActiveProject] = useState<ProjectInfo>();

    const updateProjects = (projects: Array<ProjectInfo>) => {
        setProjects(projects);
    };

    const updateActiveProject = (project: ProjectInfo) => {
        setActiveProject(project);
    };

    return (
        <PluginDataContext.Provider value={{ projects, activeProject, setProjects: updateProjects, setActiveProject: updateActiveProject }}>
            {children}
        </PluginDataContext.Provider>
    );
};

export default PluginDataProvider;
