import { useLayoutEffect, useState } from "react";
import { Typography } from "@mui/material";
import { styled } from "@mui/material/styles";
import { useTranslation } from "react-i18next";
import { usePluginHandler } from "../../Contexts/PluginHandler/PluginHandlerContext";
import getCompanySpecificTranslation from "../../Localisation/getCompanySpecificTranslation";
import { useParams } from "react-router-dom";

const Container = styled("div")(({ theme }) => ({
    padding: 15,
    textAlign: "center",
    position: "fixed",
    bottom: 0,
    width: "100vw",
    userSelect: "none",
}));

const Footer = () => {
    const { plugin } = usePluginHandler();
    const [version, setVersion] = useState<string>();
    const params = useParams();
    const { t } = useTranslation(["footer"]);

    useLayoutEffect(() => {
        if (!plugin) return;

        plugin.getPluginVersionAsync().then((x) => setVersion(x.toString()));
    }, [plugin]);

    return (
        <Container>
            <Typography variant="h5">
                {t(getCompanySpecificTranslation("CopyrightNotice", params), { year: new Date().getFullYear() })}
            </Typography>
            <Typography variant="h5">{t("VersionInformation", { version: `${version}` })}</Typography>
        </Container>
    );
};

export default Footer;
