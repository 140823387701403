import { Button } from "@mui/material";
import { styled } from "@mui/material/styles";
import { useNavigate, useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";
import Footer from "../Components/Footer/Footer";
import { usePluginHandler } from "../Contexts/PluginHandler/PluginHandlerContext";
import { useEffect } from "react";
import Logo from "../Components/Logo/Logo";
import getCompanySpecificTranslation from "../Localisation/getCompanySpecificTranslation";

const Container = styled("div")(() => ({
    display: "flex",
    flexDirection: "column",
    height: "calc(100vh - 62px)",
    justifyContent: "center",
    textAlign: "center",
}));

const LogoContainer = styled("div")(() => ({
    marginBottom: 25,
}));

const ButtonContainer = styled("div")(() => ({
    margin: "30px auto",
}));

const Landing = () => {
    const { plugin } = usePluginHandler();
    const navigate = useNavigate();
    const params = useParams();
    const { t } = useTranslation(["landing"]);

    useEffect(() => {
        window.onLoginEvent = () => {
            handleLogin();
        };

        return () => {
            window.onLoginEvent = undefined;
        };

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const handleLogin = () => {
        plugin!.getTokenAsync().then((isLoggedIn) => {
            if (isLoggedIn) {
                if (params.company) navigate(`/${params.company}/projects`);
                else navigate("/projects");
            }
        });
    };

    return (
        <>
            <Container>
                <LogoContainer>
                    <Logo width={200} />
                </LogoContainer>
                <ButtonContainer>
                    <Button variant={"contained"} onClick={handleLogin}>
                        {t(getCompanySpecificTranslation("LoginButton", params))}
                    </Button>
                </ButtonContainer>
            </Container>
            <Footer />
        </>
    );
};

export default Landing;
