import commonTranslations from "./common.json";
import footerTranslations from "./footer.json";
import landingTranslations from "./landing.json";
import projectsTranslations from "./projects.json";
import projectTranslations from "./project.json";
import ifcExportTranslations from "./ifc-export.json";
import viewsExportTranslations from "./views-export.json";
import bcfExportTranslations from "./bcf.json";
import modelSynchronisationExportTranslations from "./model-synchronisation.json";

const en = {
    common: commonTranslations,
    footer: footerTranslations,
    landing: landingTranslations,
    projects: projectsTranslations,
    project: projectTranslations,
    "ifc-export": ifcExportTranslations,
    "views-export": viewsExportTranslations,
    bcf: bcfExportTranslations,
    "model-synchronisation": modelSynchronisationExportTranslations,
};

export default en;
