import { createBrowserRouter } from "react-router-dom";
import Page from "./Components/Page/Page";
import App from "./App";
import Landing from "./Pages/Landing";
import BaseDataContainer from "./Components/BaseDataContainer/BaseDataContainer";
import Projects from "./Pages/Projects";
import Project from "./Pages/Project";
import ViewsExport from "./Pages/ViewsExport";
import IfcExport from "./Pages/IfcExport";
import BcfOverview from "./Pages/BcfOverview";
import Modal from "./Components/Modal/Modal";
import ModelSynchronisation from "./Pages/ModelSynchronisation";
import BcfEditIssue from "./Pages/BcfEditIssue";
import BcfCreateIssue from "./Pages/BcfCreateIssue";

const router = createBrowserRouter([
    {
        path: "/:company?/*",
        element: <App />,
        children: [
            {
                index: true,
                element: <Landing />,
            },
            {
                path: "*",
                element: <BaseDataContainer />,
                children: [
                    {
                        path: "*",
                        element: <Page />,
                        children: [
                            {
                                path: "projects",
                                element: <Projects />,
                            },
                            {
                                path: "project",
                                element: <Project />,
                            },
                            {
                                path: "publish",
                                children: [
                                    {
                                        path: "ifc",
                                        element: <IfcExport />,
                                    },
                                    {
                                        path: "2d",
                                        element: <ViewsExport />,
                                    },
                                ],
                            },
                            {
                                path: "bcf",
                                children: [
                                    {
                                        index: true,
                                        element: <BcfOverview />,
                                    },
                                ],
                            },
                        ],
                    },
                    {
                        path: "*",
                        element: <Modal />,
                        children: [
                            {
                                path: "bcf/topics",
                                children: [
                                    {
                                        path: "new",
                                        element: <BcfCreateIssue />,
                                    },
                                    {
                                        path: ":topicid",
                                        element: <BcfEditIssue />,
                                    },
                                ],
                            },
                            {
                                path: "synchronisation",
                                children: [
                                    {
                                        index: true,
                                        element: <ModelSynchronisation />,
                                    },
                                ],
                            },
                        ],
                    },
                ],
            },
        ],
    },
]);

export default router;
