import { useNavigate, useParams } from "react-router-dom";
import { Card, Typography } from "@mui/material";
import { styled } from "@mui/material/styles";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChevronRight, faHomeLgAlt } from "@fortawesome/pro-thin-svg-icons";
import { IPluginDataContext, usePluginData } from "../../Contexts/PluginData/PluginDataContext";

const Title = styled(Card)(() => ({
    display: "flex",
    minHeight: 60,
    height: 60,
    maxHeight: 60,
    alignItems: "center",
    "&>div": {
        whiteSpace: "nowrap",
    },
    padding: "20px 10px 20px 10px",
    borderRadius: 0,
}));

const Link = styled("span")(() => ({
    cursor: "pointer",
}));

export interface PageHeaderProps {
    title?: string;
    titleOnly?: boolean;
}

const PageHeader = ({ title, titleOnly }: PageHeaderProps): JSX.Element => {
    const { activeProject: project } = usePluginData() as IPluginDataContext;
    const navigate = useNavigate();
    const params = useParams();

    const navigateTo = (path: string) => {
        if (params.company) navigate(`/${params.company}${path}`);
        else navigate(path);
    };

    return (
        <>
            <Title>
                {!titleOnly && (
                    <>
                        <Typography variant="h2" sx={{ display: "inline-block" }}>
                            <Link onClick={() => navigateTo("/project")}>
                                <FontAwesomeIcon icon={faHomeLgAlt} style={{ marginRight: 5 }} />
                            </Link>
                        </Typography>
                        <div style={{ minWidth: 0 }}>
                            <Typography
                                variant="h2"
                                sx={{ overflow: "hidden", whiteSpace: "nowrap", textOverflow: "ellipsis" }}
                            >
                                <Link onClick={() => navigateTo("/project")}>{project?.Name}</Link>
                            </Typography>
                        </div>
                    </>
                )}

                {title && (
                    <div
                        style={{
                            display: "flex",
                            alignItems: "center",
                        }}
                    >
                        {!titleOnly && (
                            <Typography variant="h5" sx={{ display: "inline-block" }}>
                                <FontAwesomeIcon icon={faChevronRight} style={{ marginLeft: 10, marginRight: 10 }} />
                            </Typography>
                        )}
                        <Typography variant="h2" sx={{ display: "inline-block" }}>
                            {title}
                        </Typography>
                    </div>
                )}
            </Title>
        </>
    );
};

export default PageHeader;
