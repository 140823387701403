import { styled } from "@mui/material/styles";
import { Card, Typography } from "@mui/material";

const Title = styled(Card)(() => ({
    marginTop: 10,
    display: "flex",
    alignItems: "center",
    borderRadius: 0,
    minHeight: 60,
    maxHeight: 60,
    userSelect: "none",
    paddingLeft: 20,
}));

export interface ModalHeaderProps {
    title: string;
}

const ModalHeader = ({ title }: ModalHeaderProps): JSX.Element => {
    return (
        <Title>
            <Typography variant="h2">{title}</Typography>
        </Title>
    );
};

export default ModalHeader;
