import { styled } from "@mui/material/styles";
import { Outlet } from "react-router-dom";

const Container = styled("div")(() => ({
    position: "relative",
    display: "flex",
    flexDirection: "column",
    height: "100vh",
    userSelect: "none",
}));

const Modal = () => {
    return (
        <Container>
            <Outlet />
        </Container>
    );
};

export default Modal;
