import { useNavigate, useParams } from "react-router-dom";
import { Button, Link, Typography } from "@mui/material";
import { styled } from "@mui/material/styles";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCloudUpload, faExclamationCircle, faFileExport, faSync } from "@fortawesome/pro-thin-svg-icons";
import PageHeader from "../Components/PageHeader/PageHeader";
import { usePluginHandler } from "../Contexts/PluginHandler/PluginHandlerContext";
import { useTranslation } from "react-i18next";

const Container = styled("div")(() => ({
    display: "flex",
    flexDirection: "column",
}));

const ContentContainer = styled("div")(() => ({
    margin: 5,
    display: "flex",
    flexDirection: "column",
    flex: "1",
}));

const ActionsContainer = styled("div")(() => ({
    display: "flex",
    flexWrap: "wrap",
    overflow: "auto",
}));

const ActionContainer = styled("div")(({ theme }) => ({
    margin: 5,
    flex: 1,
    position: "relative",
    [theme.breakpoints.down(380)]: {
        minWidth: "calc(100% - 20px)",
        paddingBottom: "50%",
    },
    [theme.breakpoints.up(380)]: {
        minWidth: "calc(50% - 20px)",
        paddingBottom: "50%",
    },
    [theme.breakpoints.up("md")]: {
        minWidth: "calc(33% - 20px)",
        paddingBottom: "33%",
    },
    [theme.breakpoints.up("lg")]: {
        minWidth: "calc(25% - 20px)",
        paddingBottom: "25%",
    },
}));

const ActionPlaceholder = styled("div")(({ theme }) => ({
    margin: "0 10px",
    flex: 1,
    position: "relative",
    [theme.breakpoints.down(380)]: {
        minWidth: "calc(100% - 20px)",
    },
    [theme.breakpoints.up(380)]: {
        minWidth: "calc(50% - 20px)",
    },
    [theme.breakpoints.up("md")]: {
        minWidth: "calc(33% - 20px)",
    },
    [theme.breakpoints.up("lg")]: {
        minWidth: "calc(25% - 20px)",
    },
}));

const ActionButton = styled(Button)(() => ({
    textAlign: "center",
    display: "flex",
    flexDirection: "column",
    position: "absolute",
    top: 0,
    bottom: 0,
    left: 0,
    right: 0,
}));

const Project = () => {
    const { plugin } = usePluginHandler();
    const navigate = useNavigate();
    const params = useParams();
    const { t } = useTranslation(["common", "project"]);

    const onSynchronisation = () => {
        if (!plugin) return;

        let target = `${window.location.origin}/synchronisation`;
        if (params.company) target = `${window.location.origin}/${params.company}/synchronisation`;
        plugin.openDialogAsync("model-synchronisation", target, 730, 900);
    };

    const navigateTo = (path: string) => {
        if (params.company) navigate(`/${params.company}${path}`);
        else navigate(path);
    };

    return (
        <Container>
            <PageHeader />

            <ContentContainer>
                <div style={{ display: "flex", margin: 10, alignSelf: "center" }}>
                    <Link href="#" onClick={() => navigateTo("/projects?forced")}>
                        {t("ChangeProjectLink", { ns: "project" })}
                    </Link>
                </div>

                <ActionsContainer>
                    <ActionContainer>
                        <ActionButton fullWidth onClick={() => navigateTo("/publish/ifc")}>
                            <FontAwesomeIcon icon={faCloudUpload} size="3x" style={{ marginBottom: 20 }} />
                            <Typography variant="h2">{t("PublishIfcButton", { ns: "project" })}</Typography>
                        </ActionButton>
                    </ActionContainer>

                    <ActionContainer>
                        <ActionButton fullWidth onClick={() => navigateTo("/publish/2d")}>
                            <FontAwesomeIcon icon={faFileExport} size="3x" style={{ marginBottom: 20 }} />
                            <Typography variant="h2">{t("Export2DButton", { ns: "project" })}</Typography>
                        </ActionButton>
                    </ActionContainer>

                    <ActionContainer>
                        <ActionButton fullWidth onClick={() => onSynchronisation()}>
                            <FontAwesomeIcon icon={faSync} size="3x" style={{ marginBottom: 20 }} />
                            <Typography variant="h2">{t("SynchronisationButton", { ns: "project" })}</Typography>
                        </ActionButton>
                    </ActionContainer>

                    <ActionContainer>
                        <ActionButton fullWidth onClick={() => navigateTo("/bcf")}>
                            <FontAwesomeIcon icon={faExclamationCircle} size="3x" style={{ marginBottom: 20 }} />
                            <Typography variant="h2">{t("BcfButton", { ns: "project" })}</Typography>
                        </ActionButton>
                    </ActionContainer>

                    <ActionPlaceholder />
                    <ActionPlaceholder />
                    <ActionPlaceholder />
                    <ActionPlaceholder />
                </ActionsContainer>
            </ContentContainer>
        </Container>
    );
};

export default Project;
