import { CircularProgress } from "@mui/material";
import { styled } from "@mui/material/styles";

const Container = styled("div")(() => ({
    display: "flex",
    flex: "100%",
    height: "100%",
    flexDirection: "column",
    "&>*": {
        margin: "auto",
    },
}));

const LoadingIndicator = () => {
    return (
        <Container>
            <CircularProgress color="primary" size={70} />
        </Container>
    );
};

export default LoadingIndicator;
