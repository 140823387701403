export interface ExportTarget {
    Name: string;
    Type: ExportTargetType;
    TypeName?: string;
    RevitElementId: number;
    ArchicadTargetId: string;
    ExportOption?: string;
}

export enum ExportTargetType {
    dwg,
    pdf,
}
