import { styled } from "@mui/material/styles";
import { Button, Card, IconButton, MobileStepper, Paper, Typography } from "@mui/material";
import { BcfViewpoint } from "../../Connectors/Models/BcfViewpoint";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChevronLeft, faChevronRight, faLocation, faPlus, faMagnifyingGlassPlus, faTimes, faTrashAlt } from "@fortawesome/pro-thin-svg-icons";
import { Box } from "@mui/system";
import { useEffect, useState } from "react";
import { usePluginHandler } from "../../Contexts/PluginHandler/PluginHandlerContext";
import { useTranslation } from "react-i18next";

const Container = styled(Card)(({ theme }) => ({
    marginRight: 15,
    flex: "1",
    maxWidth: 300,
    [theme.breakpoints.down(600)]: {
        minWidth: "100%",
        marginBottom: 15,
    },
    [theme.breakpoints.up(600)]: {},
}));

const ContentContainer = styled(Box)(({ theme }) => ({
    marginRight: 15,
    width: "100%",
    [theme.breakpoints.down(600)]: {
        minWidth: "100%",
        marginBottom: 15,
    },
    [theme.breakpoints.up(600)]: {},
}));

const ViewpointContainer = styled(Box)(({ theme }) => ({
    width: "100%",
    flex: 1,
    maxWidth: 400,
    p: 2,
    [theme.breakpoints.down(600)]: {
        minWidth: "100%",
        maxWidth: "unset",
        height: 180,
    },
}));

const ZoomContainer = styled("div")(() => ({
    position: "absolute",
    top: 0,
    bottom: 0,
    left: 0,
    right: 0,
    display: "flex",
    color: "white",
    cursor: "pointer",
    "&:hover > *": {
        visibility: "visible",
    },
}));

const ZoomTrigger = styled("div")(() => ({
    display: "flex",
    flex: "1 1 100%",
    backgroundColor: "rgba(0, 0, 0, 0.4)",
    visibility: "hidden",
    "&:hover": {
        visibility: "visible",
    },
}));

const ActionsContainer = styled("div")(() => ({
    display: "flex",
    position: "absolute",
    left: 5,
    bottom: 5,
    right: 5,
}));

const FullscreenContainer = styled(Card)((props) => ({
    position: "absolute",
    top: 0,
    bottom: 0,
    left: 0,
    right: 0,
    zIndex: 90,
    display: "flex",
    flexDirection: "column",
    backgroundColor: "#FFFFFFBB",
    paddingTop: 90,
}));

export interface ViewpointProps {
    viewpoints: Array<BcfViewpoint>;
    onAdd: () => void;
    onRemove: (viewpoint: BcfViewpoint) => void;
}

const Viewpoints = ({ viewpoints, onAdd, onRemove }: ViewpointProps) => {
    const { plugin } = usePluginHandler();
    const [activeStep, setActiveStep] = useState(0);
    const maxSteps = viewpoints.length;
    const [isFullscreen, setIsFullscreen] = useState<boolean>(false);

    const { t } = useTranslation(["common", "bcf"]);

    useEffect(() => {
        setActiveStep(maxSteps - 1);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [viewpoints]);

    const handleNext = () => {
        setActiveStep((prevActiveStep) => prevActiveStep + 1);
    };

    const handleBack = () => {
        setActiveStep((prevActiveStep) => prevActiveStep - 1);
    };

    const activeViewpoint = viewpoints[activeStep];

    const onViewViewpoint = () => {
        if (!plugin) return;

        plugin.showViewpointAsync(activeViewpoint);
    };

    return (
        <>
            <Container variant="outlined">
                <ContentContainer sx={{ maxWidth: 400, height: "100%", flexGrow: 1, display: "flex", flexDirection: "column" }}>
                    <ViewpointContainer
                        sx={{
                            position: "relative",
                            backgroundImage:
                                maxSteps > 0
                                    ? `url(data:image/png;base64,${activeViewpoint?.snapshot?.snapshotData.replace(
                                          "data:image/png;base64,",
                                          ""
                                      )})`
                                    : `url(placeholder.png)`,
                            backgroundSize: "cover",
                            backgroundPosition: "center",
                            backgroundRepeat: "no-repeat",
                        }}
                    >
                        {maxSteps > 0 && (
                            <ZoomContainer>
                                <ZoomTrigger onClick={() => setIsFullscreen(true)}>
                                    <FontAwesomeIcon icon={faMagnifyingGlassPlus} style={{ margin: "auto" }} size="5x" />
                                </ZoomTrigger>
                            </ZoomContainer>
                        )}
                        <ActionsContainer>
                            <Button sx={{ minHeight: "unset" }} startIcon={<FontAwesomeIcon icon={faPlus} />} onClick={() => onAdd()}>
                                {t("ButtonAddViewpointLabel", { ns: "bcf" })}
                            </Button>
                            {maxSteps > 0 && (
                                <>
                                    <Button
                                        sx={{ minHeight: "unset", marginLeft: "5px" }}
                                        startIcon={<FontAwesomeIcon icon={faLocation} />}
                                        onClick={onViewViewpoint}
                                    >
                                        {t("ButtonViewViewpointLabel", { ns: "bcf" })}
                                    </Button>

                                    <IconButton
                                        sx={{
                                            minHeight: "unset",
                                            marginLeft: "auto",
                                            fontSize: 18,
                                            width: 36,
                                            height: 36,
                                            backgroundColor: "#d32f2f",
                                            color: "white",
                                            "&:hover": {
                                                backgroundColor: "#e24040",
                                            },
                                        }}
                                        onClick={() => onRemove(viewpoints[activeStep])}
                                    >
                                        <FontAwesomeIcon icon={faTrashAlt} />
                                    </IconButton>
                                </>
                            )}
                        </ActionsContainer>
                    </ViewpointContainer>
                    {maxSteps > 0 ? (
                        <MobileStepper
                            variant="text"
                            steps={maxSteps}
                            position="static"
                            activeStep={activeStep}
                            nextButton={
                                <IconButton
                                    size="small"
                                    onClick={handleNext}
                                    disabled={activeStep === maxSteps - 1 || maxSteps === 0}
                                    sx={{ minHeight: "unset", fontSize: 12, width: 22, height: 22 }}
                                >
                                    <FontAwesomeIcon icon={faChevronRight} />
                                </IconButton>
                            }
                            backButton={
                                <IconButton
                                    size="small"
                                    onClick={handleBack}
                                    disabled={activeStep === 0}
                                    sx={{ minHeight: "unset", fontSize: 12, width: 22, height: 22 }}
                                >
                                    <FontAwesomeIcon icon={faChevronLeft} />
                                </IconButton>
                            }
                            sx={{ fontSize: 12 }}
                        />
                    ) : (
                        <Paper
                            square
                            elevation={0}
                            sx={{
                                display: "flex",
                                alignItems: "center",
                                textAlign: "center",
                                pl: 2,
                                bgcolor: "background.default",
                                height: 38,
                            }}
                        >
                            <Typography variant="body2" sx={{ margin: "auto", fontSize: 12 }}>
                                {t("NoViewpoints", { ns: "bcf" })}
                            </Typography>
                        </Paper>
                    )}
                </ContentContainer>
            </Container>
            {isFullscreen && (
                <FullscreenContainer>
                    <ViewpointContainer
                        sx={{
                            position: "relative",
                            backgroundImage:
                                maxSteps > 0
                                    ? `url(data:image/png;base64,${activeViewpoint?.snapshot?.snapshotData.replace(
                                          "data:image/png;base64,",
                                          ""
                                      )})`
                                    : `url(placeholder.png)`,
                            backgroundSize: "contain",
                            backgroundPosition: "center",
                            backgroundRepeat: "no-repeat",
                            flex: "1 1 100%",
                            maxWidth: "unset",
                        }}
                    >
                        <IconButton
                            sx={{
                                minHeight: "unset",
                                float: "right",
                                fontSize: 18,
                                width: 36,
                                height: 36,
                                backgroundColor: "#f3f3fa99",
                                marginRight: "20px",
                                "&:hover": {
                                    backgroundColor: "#f3f3faff",
                                },
                            }}
                            onClick={() => setIsFullscreen(false)}
                        >
                            <FontAwesomeIcon icon={faTimes} />
                        </IconButton>
                    </ViewpointContainer>
                    <MobileStepper
                        variant="text"
                        steps={maxSteps}
                        position="static"
                        activeStep={activeStep}
                        nextButton={
                            <IconButton
                                size="small"
                                onClick={handleNext}
                                disabled={activeStep === maxSteps - 1 || maxSteps === 0}
                                sx={{ minHeight: "unset", fontSize: 12, width: 22, height: 22 }}
                            >
                                <FontAwesomeIcon icon={faChevronRight} />
                            </IconButton>
                        }
                        backButton={
                            <IconButton
                                size="small"
                                onClick={handleBack}
                                disabled={activeStep === 0}
                                sx={{ minHeight: "unset", fontSize: 12, width: 22, height: 22 }}
                            >
                                <FontAwesomeIcon icon={faChevronLeft} />
                            </IconButton>
                        }
                        sx={{ fontSize: 12 }}
                    />
                </FullscreenContainer>
            )}
        </>
    );
};

export default Viewpoints;
