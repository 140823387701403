import { useLayoutEffect, useState } from "react";
import { faFolder, faFolderOpen } from "@fortawesome/pro-thin-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { FormControl, InputLabel, MenuItem, Select, SelectChangeEvent } from "@mui/material";
import { useTranslation } from "react-i18next";
import { FolderInfo } from "../../Connectors/Models/FolderInfo";

interface IFolderSelectProps {
    label: string;
    value?: string;
    folders?: FolderInfo;
    onChange: (e: SelectChangeEvent<string>) => void;
}

const flattenFolders = (items: any, indent = 0) => {
    return items.reduce((folders: Array<any>, item: FolderInfo) => {
        item.Indent = indent;
        const childFolders = flattenFolders(item.Children, indent + 1);

        return [...folders, item, ...childFolders];
    }, []);
};

const FolderSelect = ({ label, value, folders, onChange }: IFolderSelectProps) => {
    const [folderStructure, setFolderStructure] = useState<Array<FolderInfo>>();
    const { t } = useTranslation();

    useLayoutEffect(() => {
        if (!folders) return;

        const flat = flattenFolders([folders]);
        setFolderStructure(flat);
    }, [folders]);

    const renderFolderStructure = () => {
        if (!folderStructure)
            return (
                <MenuItem value={-1} disabled>
                    {t("DataLoading")}
                </MenuItem>
            );

        return folderStructure.map((folderInfo) => (
            <MenuItem value={folderInfo.Id} key={folderInfo.Id} sx={{ paddingLeft: `${16 * (1 + folderInfo.Indent)}px` }}>
                <FontAwesomeIcon icon={folderInfo.Children.length === 0 ? faFolder : faFolderOpen} style={{ marginRight: 6 }} />
                {folderInfo.Name}
            </MenuItem>
        ));
    };

    return (
        <FormControl fullWidth>
            <InputLabel id="destination-folder-label">{label}</InputLabel>
            <Select id="destination-folder-select" labelId="destination-folder-label" label={label} value={value} onChange={onChange}>
                {renderFolderStructure()}
            </Select>
        </FormControl>
    );
};

export default FolderSelect;
