import localhost from "./Localhost";
import development from "./Development";
import production from "./Production";

export interface Config {
    language: "en" | "fr";
    sentry?: SentryConfiguration;
}

interface SentryConfiguration {
    dsn: string;
    environment: string;
    tracesSampleRate: number;
}

const getEnvironmentConfiguration = (environment?: string): Config => {
    switch (environment) {
        case "development":
            return development;
        case "production":
            return production;
        case "localhost":
        default:
            return localhost;
    }
};

export const configuration = { ...getEnvironmentConfiguration(process.env.REACT_APP_STAGE) };
