import { useLayoutEffect, useState } from "react";
import { Avatar } from "@mui/material";
import { styled, useTheme } from "@mui/material/styles";
import { usePluginHandler } from "../../Contexts/PluginHandler/PluginHandlerContext";
import Logo from "../Logo/Logo";
import { UserInfo } from "../../Connectors/Models/UserInfo";

const Container = styled("div")(({ theme }) => ({
    padding: 10,
    display: "flex",
    justifyContent: "space-between",
    borderRadius: 0,
    minHeight: 80,
    maxHeight: 80,
    userSelect: "none",
}));

const Header = () => {
    const { plugin } = usePluginHandler();
    const [user, setUser] = useState<UserInfo>();
    const theme = useTheme();

    useLayoutEffect(() => {
        if (!plugin) return;
        plugin.getUserInfoAsync().then(setUser);
    }, [plugin]);

    const getInitials = () => {
        if (!user) return "";

        return `${user?.FirstName[0]}${user?.LastName[0]}`;
    };

    return (
        <Container>
            <Logo height={60} />
            <Avatar src={user?.ProfilePicture} sx={{ width: 60, height: 60, bgcolor: theme.palette.primary.main }}>
                {getInitials()}
            </Avatar>
        </Container>
    );
};

export default Header;
