import { useLayoutEffect, useState } from "react";
import {
    Accordion,
    AccordionActions,
    AccordionDetails,
    AccordionSummary,
    Button,
    Checkbox,
    FormControl,
    Grid,
    InputLabel,
    MenuItem,
    Select,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Typography,
} from "@mui/material";
import { styled } from "@mui/material/styles";
import { usePluginHandler } from "../Contexts/PluginHandler/PluginHandlerContext";
import ModalHeader from "../Components/ModalHeader/ModalHeader";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChevronDown, faMagnifyingGlass } from "@fortawesome/pro-thin-svg-icons";
import LoadingIndicator from "../Components/LoadingIndicator/LoadingIndicator";
import { IPluginDataContext, usePluginData } from "../Contexts/PluginData/PluginDataContext";
import { IArchicadSynchronisationElement, IRevitSynchronisationElement, ISynchronisationElement } from "../Connectors/Models/ISynchronisationElement";
import { IArchicadSynchronisationProperty, IRevitSynchronisationProperty, ISynchronisationProperty } from "../Connectors/Models/ISynchronisationProperty";
import { DocumentStatus } from "../Connectors/Models/DocumentStatus";
import { useTranslation } from "react-i18next";

const Container = styled("div")(() => ({
    display: "flex",
    flexDirection: "column",
    flex: 1,
}));

const ContentContainer = styled("div")(() => ({
    display: "flex",
    flexDirection: "column",
    flex: 1,
}));

const InfoContainer = styled("div")(() => ({
    margin: "20px 10px 10px 10px",
    minHeight: "20px",
    maxHeight: "20px",
    alignItems: "center",
    display: "flex",
    flexDirection: "row",
}));

const ElementContainer = styled("div")(() => ({
    margin: 5,
    padding: 5,
    display: "flex",
    flexDirection: "column",
    overflow: "auto",
    flex: 1,
}));

const ActionsContainer = styled("div")(() => ({
    display: "flex",
    margin: "auto auto 20px auto",
}));

interface ISynchronisationResultCounter {
    total: number;
    success: number;
    failed: number;
}

interface ISynchronisationResultCounters {
    elements: ISynchronisationResultCounter;
    properties: ISynchronisationResultCounter;
}

const ModelSynchronisation = () => {
    const { plugin } = usePluginHandler();

    const { activeProject: project } = usePluginData() as IPluginDataContext;
    const [isLoading, setIsLoading] = useState<boolean>(true);

    const [documentStatus, setDocumentStatus] = useState<DocumentStatus>();
    const [synchronisationState, setSynchronisationState] = useState<Array<ISynchronisationElement>>([]);
    const [synchronisationResult, setSynchronisationResult] = useState<Array<ISynchronisationElement>>([]);
    const [synchronisationResultcounters, setSynchronisationResultCounters] = useState<ISynchronisationResultCounters>();

    const [translators, setTranslators] = useState<Array<string>>([]);
    const [translator, setTranslator] = useState<string>("");
    const [synchronisationFetched, setSynchronisationFetched] = useState<boolean>(false);

    const { t } = useTranslation(["common", "model-synchronisation"]);

    useLayoutEffect(() => {
        if (!plugin || !project) return;

        plugin.getDocumentStatusAsync().then((status) => {
            if (status && status.IfcDocumentId) {
                if (plugin.isArchicad) {
                    plugin.getImportTranslators().then((translators) => {
                        if (translators.length === 0) return getSyncState(status);
                        else {
                            setTranslators(translators);
                            setIsLoading(false);
                        }
                    });
                } else {
                    getSyncState(status);
                }
                setDocumentStatus(status);
            } else {
                setIsLoading(false);
            }
        });

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [plugin, project]);

    const getSyncState = (status: DocumentStatus) => {
        if (!plugin || !project) return;

        plugin.getSynchronisationStateAsync(status.CloudId, status.ProjectId, Number(status.IfcDocumentId), translator).then((state) => {
            setIsLoading(false);

            // Only set the properties that actually have changes to be marked for update
            state.forEach((element) => {
                element.Properties = element.Properties.filter((property) => property.HasChanges);
            });

            setSynchronisationState(state.filter((element) => element.HasChanges));
            setSynchronisationFetched(true);
        });
    };

    const onSynchronise = () => {
        if (!plugin || !documentStatus) return;

        setIsLoading(true);

        plugin
            .synchroniseAsync(documentStatus.CloudId, documentStatus.ProjectId, Number(documentStatus.IfcDocumentId), synchronisationState, translator)
            .then((elements) => {
                const counters: ISynchronisationResultCounters = {
                    elements: {
                        total: 0,
                        failed: 0,
                        success: 0,
                    },
                    properties: {
                        total: 0,
                        failed: 0,
                        success: 0,
                    },
                };

                const result: ISynchronisationElement[] = [];
                elements.forEach((element) => {
                    let handleElement = false;

                    element.Properties.forEach((property) => {
                        if (property.MarkedForUpdate) {
                            handleElement = true;

                            counters.properties.total += 1;
                            if (property.SynchronizedOk) counters.properties.success += 1;
                            else counters.properties.failed += 1;
                        }
                    });

                    if (handleElement) {
                        counters.elements.total += 1;
                        if (element.SynchronizedOk) counters.elements.success += 1;
                        else counters.elements.failed += 1;

                        const copy = { ...element };
                        copy.Properties = copy.Properties.filter((property) => !property.SynchronizedOk);

                        result.push(copy);
                    }
                });

                setIsLoading(false);
                setSynchronisationResult(result);
                setSynchronisationResultCounters(counters);
            });
    };

    const onClose = () => {
        if (!plugin) return;

        plugin.closeDialogAsync("model-synchronisation");
    };

    return (
        <Container>
            <ModalHeader title={t("PageTitle", { ns: "model-synchronisation" })} />

            {isLoading ? (
                <div style={{ display: "flex", flex: 1, minHeight: "calc(100vh - 100px)", marginTop: 20 }}>
                    <LoadingIndicator />
                </div>
            ) : (
                <ContentContainer>
                    {synchronisationResult.length > 0 ? (
                        <>
                            <InfoContainer>
                                <Typography variant="h5">
                                    {t("SynchronisationResultMessage", {
                                        ns: "model-synchronisation",
                                        propertiesSuccess: t("SynchronisationResultMessagePropertiesCount", {
                                            ns: "model-synchronisation",
                                            count: synchronisationResultcounters?.properties.success,
                                        }),
                                        elementsSuccess: t("SynchronisationResultMessageElementsCount", {
                                            ns: "model-synchronisation",
                                            count: synchronisationResultcounters?.elements.success,
                                        }),
                                        propertiesFailed: t("SynchronisationResultMessagePropertiesCount", {
                                            ns: "model-synchronisation",
                                            count: synchronisationResultcounters?.properties.failed,
                                        }),
                                        elementsFailed: t("SynchronisationResultMessageElementsCount", {
                                            ns: "model-synchronisation",
                                            count: synchronisationResultcounters?.elements.failed,
                                        }),
                                    })}
                                </Typography>
                            </InfoContainer>
                            {(synchronisationResultcounters?.elements.failed ?? -1) > 0 && (
                                <ElementContainer>
                                    <Grid container spacing={2}>
                                        <Grid item xs={12}>
                                            <Typography>{t("SynchronisationResultMessageFailed", { ns: "model-synchronisation" })}</Typography>
                                        </Grid>
                                        {synchronisationResult
                                            .filter((element) => element.HasChanges && !element.SynchronizedOk)
                                            .map((element) => {
                                                if (element.Type === "REVIT") {
                                                    const rvtElem = element as IRevitSynchronisationElement;
                                                    return <ElementOverviewResult key={rvtElem.ElementId} type={element.Type} element={element} />;
                                                } else if (element.Type === "ARCHICAD") {
                                                    const arcElem = element as IArchicadSynchronisationElement;
                                                    return <ElementOverviewResult key={arcElem.ArchicadElementId} type={element.Type} element={element} />;
                                                }

                                                return null;
                                            })}
                                    </Grid>
                                </ElementContainer>
                            )}
                            <ActionsContainer style={{ display: "flex", margin: "auto" }}>
                                <Button onClick={() => onClose()}>{t("ButtonOkLabel")}</Button>
                            </ActionsContainer>
                        </>
                    ) : (
                        <>
                            {!Boolean(documentStatus?.IfcDocumentId) && (
                                <>
                                    <InfoContainer>
                                        <Typography variant="h5">{t("NoLinkedDocument", { ns: "model-synchronisation" })}</Typography>
                                    </InfoContainer>

                                    <ActionsContainer>
                                        <Button variant="outlined" onClick={() => onClose()} sx={{ marginLeft: "8px" }}>
                                            {t("ButtonCancelLabel", { ns: "model-synchronisation" })}
                                        </Button>
                                    </ActionsContainer>
                                </>
                            )}

                            {Boolean(plugin?.isArchicad) && translators.length === 0 && (
                                <>
                                    <InfoContainer>
                                        <Typography variant="h5">{t("ArchicadNoValidTranslators", { ns: "model-synchronisation" })}</Typography>
                                    </InfoContainer>

                                    <ActionsContainer>
                                        <Button variant="outlined" onClick={() => onClose()} sx={{ marginLeft: "8px" }}>
                                            {t("ButtonCancelLabel", { ns: "model-synchronisation" })}
                                        </Button>
                                    </ActionsContainer>
                                </>
                            )}

                            {translators.length > 0 && !synchronisationFetched && (
                                <div style={{ display: "flex", minHeight: "fit-content", margin: "20px 10px 10px 10px" }}>
                                    <FormControl fullWidth>
                                        {translator.length > 0 && <InputLabel id="translators-select-label">Translator</InputLabel>}
                                        <Select
                                            id="translators-select"
                                            labelId="translators-label"
                                            label={"Translator"}
                                            value={translator}
                                            onChange={(e) => {
                                                setTranslator(e.target.value as string);
                                            }}
                                            required
                                            style={{ width: 250 }}
                                            placeholder={"Translator"}
                                        >
                                            {translators.map((option, index) => (
                                                <MenuItem value={option} key={index}>
                                                    {option}
                                                </MenuItem>
                                            ))}
                                        </Select>
                                    </FormControl>
                                    <Button onClick={() => getSyncState(documentStatus!)} disabled={!Boolean(translator)}>
                                        Check for changes
                                    </Button>
                                </div>
                            )}

                            {synchronisationFetched && (
                                <>
                                    <InfoContainer>
                                        <Typography variant="h5">
                                            {t("ElementChangeCountOverview", {
                                                ns: "model-synchronisation",
                                                count: synchronisationState.filter((element) => element.HasChanges).length,
                                            })}
                                        </Typography>
                                    </InfoContainer>
                                    <ElementContainer>
                                        <Grid container spacing={2}>
                                            {synchronisationState
                                                .filter((element) => element.HasChanges)
                                                .map((element) => {
                                                    if (element.Type === "REVIT") {
                                                        const rvtElem = element as IRevitSynchronisationElement;
                                                        return <ElementOverview key={rvtElem.ElementId} type={element.Type} element={element} />;
                                                    } else if (element.Type === "ARCHICAD") {
                                                        const arcElem = element as IArchicadSynchronisationElement;
                                                        return <ElementOverview key={arcElem.ArchicadElementId} type={element.Type} element={element} />;
                                                    }

                                                    return null;
                                                })}
                                        </Grid>
                                    </ElementContainer>

                                    <ActionsContainer>
                                        <Button onClick={() => onSynchronise()} disabled={synchronisationState.every((element) => !element.HasChanges)}>
                                            {t("ButtonSynchroniseLabel", { ns: "model-synchronisation" })}
                                        </Button>
                                        <Button variant="outlined" onClick={() => onClose()} sx={{ marginLeft: "8px" }}>
                                            {t("ButtonCancelLabel", { ns: "model-synchronisation" })}
                                        </Button>
                                    </ActionsContainer>
                                </>
                            )}
                        </>
                    )}
                </ContentContainer>
            )}
        </Container>
    );
};

export default ModelSynchronisation;

interface IElementSynchronisationDisplayProps {
    element: ISynchronisationElement;
    type: "ARCHICAD" | "REVIT";
}

const ElementOverview = ({ element, type }: IElementSynchronisationDisplayProps) => {
    const { plugin } = usePluginHandler();
    const [properties, setProperties] = useState<Array<ISynchronisationProperty>>([]);
    const { t } = useTranslation(["common", "model-synchronisation"]);

    useLayoutEffect(() => {
        setProperties(element.Properties);
    }, [element]);

    const highlightElement = (elementId: number | string) => {
        if (!plugin) return;

        plugin.highlightElementAsync(elementId);
    };

    const getKey = (): string | number => {
        if (type === "REVIT") {
            const rvtElem = element as IRevitSynchronisationElement;
            return rvtElem.ElementId;
        } else if (type === "ARCHICAD") {
            const arcElem = element as IArchicadSynchronisationElement;
            return arcElem.ArchicadElementId;
        }

        throw new Error("Unable to get key");
    };

    const getId = (property: ISynchronisationProperty): string | number => {
        if (type === "REVIT") {
            const rvtElem = property as IRevitSynchronisationProperty;
            return rvtElem.ParameterId;
        } else if (type === "ARCHICAD") {
            const arcElem = property as IArchicadSynchronisationProperty;
            return arcElem.ArchicadParameterId;
        }

        throw new Error("Unable to get key");
    };

    const getValue = (property: ISynchronisationProperty): string => {
        if (type === "REVIT") {
            const rvtElem = property as IRevitSynchronisationProperty;
            return rvtElem.RevitValue?.toString() || "";
        } else if (type === "ARCHICAD") {
            const arcElem = property as IArchicadSynchronisationProperty;
            return arcElem.ArchicadValue?.toString() || "";
        }

        throw new Error("Unable to get key");
    };

    const renderProperties = () => {
        return properties
            .filter((property) => property.HasChanges)
            .map((property) => (
                <TableRow key={`${getKey()}:${getId(property)}`} sx={{ "&:last-child td, &:last-child th": { border: 0 } }}>
                    <TableCell component="th" scope="row" padding="checkbox">
                        <Checkbox
                            checked={property.MarkedForUpdate}
                            onClick={(event) => event.stopPropagation()}
                            onChange={(event, checked) => {
                                const props = [...properties];
                                const currentProperty = props.find(
                                    (prop) =>
                                        getId(prop) === getId(property) && prop.PropertySet === property.PropertySet && prop.Property === property.Property
                                );
                                const elementProperty = element.Properties.find(
                                    (prop) =>
                                        getId(prop) === getId(property) && prop.PropertySet === property.PropertySet && prop.Property === property.Property
                                );

                                if (currentProperty && elementProperty) {
                                    currentProperty.MarkedForUpdate = checked;
                                    elementProperty.MarkedForUpdate = checked;
                                    setProperties(props);
                                }
                            }}
                            inputProps={{ "aria-label": "controlled" }}
                        />
                    </TableCell>
                    <TableCell>{property.Property}</TableCell>
                    <TableCell>{getValue(property)}</TableCell>
                    <TableCell>{property.IfcValue.toString()}</TableCell>
                </TableRow>
            ));
    };

    return (
        <Grid item xs={12} key={getKey()}>
            <Accordion disableGutters>
                <AccordionSummary
                    expandIcon={<FontAwesomeIcon icon={faChevronDown} />}
                    sx={{ borderBottom: "1px solid #00000015", "&> div": { alignItems: "center" } }}
                >
                    <Checkbox
                        checked={properties.filter((property) => property.HasChanges).every((property) => property.MarkedForUpdate)}
                        indeterminate={
                            properties.filter((property) => property.HasChanges).some((property) => property.MarkedForUpdate) &&
                            !properties.filter((property) => property.HasChanges).every((property) => property.MarkedForUpdate)
                        }
                        onClick={(event) => event.stopPropagation()}
                        onChange={(event, value) => {
                            setProperties(properties.map((property) => ({ ...property, MarkedForUpdate: property.HasChanges && value })));
                            element.Properties.forEach((property) => (property.MarkedForUpdate = property.HasChanges && value));
                        }}
                        inputProps={{ "aria-label": "controlled" }}
                    />

                    <Typography variant="button">
                        {element.Name}
                        {getKey() !== element.Name && `: ${getKey()}`}
                    </Typography>
                </AccordionSummary>
                <AccordionDetails>
                    <TableContainer>
                        <Table size="medium">
                            <TableHead>
                                <TableRow>
                                    <TableCell width={"10%"}></TableCell>
                                    <TableCell width={"30%"}>{t("PropertiesTableHeaderPropertyTitle", { ns: "model-synchronisation" })}</TableCell>
                                    <TableCell width={"30%"}>{t("PropertiesTableHeaderCurrentValueTitle", { ns: "model-synchronisation" })}</TableCell>
                                    <TableCell width={"30%"}>{t("PropertiesTableHeaderIncomingValueTitle", { ns: "model-synchronisation" })}</TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>{renderProperties()}</TableBody>
                        </Table>
                    </TableContainer>
                </AccordionDetails>
                <AccordionActions>
                    <Button
                        startIcon={<FontAwesomeIcon icon={faMagnifyingGlass} />}
                        sx={{ minHeight: "unset", marginTop: "20px" }}
                        onClick={() => highlightElement(getKey())}
                    >
                        {t("ButtonViewInModel", { ns: "model-synchronisation" })}
                    </Button>
                </AccordionActions>
            </Accordion>
        </Grid>
    );
};

const ElementOverviewResult = ({ element, type }: IElementSynchronisationDisplayProps) => {
    const { plugin } = usePluginHandler();
    const [properties, setProperties] = useState<Array<ISynchronisationProperty>>([]);
    const { t } = useTranslation(["common", "model-synchronisation"]);

    useLayoutEffect(() => {
        setProperties(element.Properties);
    }, [element]);

    const getKey = (): string | number => {
        if (type === "REVIT") {
            const rvtElem = element as IRevitSynchronisationElement;
            return rvtElem.ElementId;
        } else if (type === "ARCHICAD") {
            const arcElem = element as IArchicadSynchronisationElement;
            return arcElem.ArchicadElementId;
        }

        throw new Error("Unable to get key");
    };

    const getId = (property: ISynchronisationProperty): string | number => {
        if (type === "REVIT") {
            const rvtElem = property as IRevitSynchronisationProperty;
            return rvtElem.ParameterId;
        } else if (type === "ARCHICAD") {
            const arcElem = property as IArchicadSynchronisationProperty;
            return arcElem.ArchicadParameterId;
        }

        throw new Error("Unable to get key");
    };

    const getValue = (property: ISynchronisationProperty): string => {
        if (type === "REVIT") {
            const rvtElem = property as IRevitSynchronisationProperty;
            return rvtElem.RevitValue?.toString() || "";
        } else if (type === "ARCHICAD") {
            const arcElem = property as IArchicadSynchronisationProperty;
            return arcElem.ArchicadValue?.toString() || "";
        }

        throw new Error("Unable to get key");
    };

    const highlightElement = (elementId: number | string) => {
        if (!plugin) return;

        plugin.highlightElementAsync(elementId);
    };

    const renderProperties = () => {
        return properties
            .filter((property) => property.HasChanges && !property.SynchronizedOk)
            .map((property) => (
                <>
                    <TableRow key={`${getKey()}:${getId(property)}`} sx={{ "&:last-child td, &:last-child th": { border: 0 } }}>
                        <TableCell>{property.Property}</TableCell>
                        <TableCell>{getValue(property)}</TableCell>
                        <TableCell>{property.IfcValue.toString()}</TableCell>
                    </TableRow>
                    <TableRow>
                        <TableCell colSpan={3}>
                            {t("SynchronisationError", { ns: "model-synchronisation" })}: {property.SyncError}
                        </TableCell>
                    </TableRow>
                </>
            ));
    };

    return (
        <Grid item xs={12} key={getKey()}>
            <Accordion disableGutters>
                <AccordionSummary
                    expandIcon={<FontAwesomeIcon icon={faChevronDown} />}
                    sx={{ borderBottom: "1px solid #00000015", "&> div": { alignItems: "center" } }}
                >
                    <Typography variant="button">
                        {element.Name}
                        {getKey() !== element.Name && `: ${getKey()}`}
                    </Typography>
                </AccordionSummary>
                <AccordionDetails>
                    <TableContainer>
                        <Table size="medium">
                            <TableHead>
                                <TableRow>
                                    <TableCell width={"40%"}>{t("PropertiesTableHeaderPropertyTitle", { ns: "model-synchronisation" })}</TableCell>
                                    <TableCell width={"30%"}>{t("PropertiesTableHeaderCurrentValueTitle", { ns: "model-synchronisation" })}</TableCell>
                                    <TableCell width={"30%"}>{t("PropertiesTableHeaderIncomingValueTitle", { ns: "model-synchronisation" })}</TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>{renderProperties()}</TableBody>
                        </Table>
                    </TableContainer>
                </AccordionDetails>
                <AccordionActions>
                    <Button
                        startIcon={<FontAwesomeIcon icon={faMagnifyingGlass} />}
                        sx={{ minHeight: "unset", marginTop: "20px" }}
                        onClick={() => highlightElement(getKey())}
                    >
                        {t("ButtonViewInModel", { ns: "model-synchronisation" })}
                    </Button>
                </AccordionActions>
            </Accordion>
        </Grid>
    );
};
