import { useLayoutEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import {
    Card,
    CardActionArea,
    CardContent,
    Divider,
    FormControl,
    Grid,
    InputLabel,
    MenuItem,
    Select,
    Typography,
} from "@mui/material";
import { styled } from "@mui/material/styles";
import { usePluginHandler } from "../Contexts/PluginHandler/PluginHandlerContext";
import { ProjectInfo } from "../Connectors/Models/ProjectInfo";
import { CloudInfo } from "../Connectors/Models/CloudInfo";
import PageHeader from "../Components/PageHeader/PageHeader";
import useQuery from "../Hooks/useQuery/useQuery";
import LoadingIndicator from "../Components/LoadingIndicator/LoadingIndicator";
import { IPluginDataContext, usePluginData } from "../Contexts/PluginData/PluginDataContext";
import ProjectImages from "../Components/ProjectImages/ProjectImages";
import { useTranslation } from "react-i18next";

const Container = styled("div")(() => ({
    display: "flex",
    flexDirection: "column",
    height: "calc(100vh - 62px)",
    userSelect: "none",
}));

const ContentContainer = styled("div")(() => ({
    display: "flex",
    flexDirection: "column",
}));

const ProjectsContainer = styled("div")(() => ({
    display: "flex",
    flexWrap: "wrap",
    overflow: "auto",
}));

const ProjectCard = styled(Card)(() => ({
    margin: 10,
    textAlign: "center",
    display: "flex",
    flexDirection: "column",
    minWidth: 160,
    maxWidth: 340,
    flex: 1,
}));

const Spacer = styled("div")(() => ({
    margin: "0 10px",
    minWidth: 160,
    maxWidth: 340,
    height: 0,
    flex: 1,
}));

const Projects = () => {
    const { plugin } = usePluginHandler();
    const { projects, activeProject, setProjects, setActiveProject } = usePluginData() as IPluginDataContext;
    const [workspaces, setWorkspaces] = useState<Array<CloudInfo>>([]);
    const [workspaceFilter, setWorkspaceFilter] = useState<string>("any");
    const [isLoading, setIsLoading] = useState<boolean>(true);
    const navigate = useNavigate();
    const params = useParams();
    const query = useQuery();
    const { t } = useTranslation(["common", "projects"]);

    useLayoutEffect(() => {
        if (!plugin) return;
        plugin.getProjectsAsync().then(setProjects);

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [plugin]);

    useLayoutEffect(() => {
        if (!plugin) return;
        plugin.getWorkspacesAsync().then(setWorkspaces);
        setIsLoading(false);

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [plugin, projects]);

    useLayoutEffect(() => {
        if (!activeProject || query.has("forced")) return;
        navigateToProject();

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [activeProject]);

    const activateProject = (project: ProjectInfo) => {
        if (!plugin) return;

        plugin.setActiveProjectAsync(project.Cloud.Id, project.Id).then((x) => {
            setActiveProject(project);
            navigateToProject();
        });
    };

    const navigateToProject = () => {
        if (params.company) navigate(`/${params.company}/project`);
        else navigate("/project");
    };

    const renderProjects = () => {
        if (!projects || projects.length === 0) return <Typography>{t("NoProjects", { ns: "projects" })}</Typography>;
        let projectList = [...projects];

        if (workspaceFilter !== "any") {
            projectList = projectList.filter((project) => project.Cloud.Id === Number(workspaceFilter));
        }

        return projectList.map((x) => (
            <ProjectCard key={x.Id} onClick={() => activateProject(x)}>
                <CardActionArea sx={{ height: "100%" }}>
                    <ProjectImages project={x} />
                    <CardContent>
                        <Typography>{x.Name}</Typography>
                    </CardContent>
                </CardActionArea>
            </ProjectCard>
        ));
    };

    return (
        <Container>
            <PageHeader title={t("PageTitle", { ns: "projects" })} titleOnly />

            <ContentContainer>
                {isLoading ? (
                    <LoadingIndicator />
                ) : (
                    <>
                        <Grid container spacing={2} sx={{ marginTop: "-6px", minHeight: 90 }}>
                            <Grid item xs={12} sm={12} md={6}>
                                <FormControl
                                    fullWidth
                                    sx={{ margin: "10px 10px 5px 10px", width: "calc(100% - 20px)" }}
                                >
                                    <InputLabel id="Workspace-label">{t("Workspace", { ns: "projects" })}</InputLabel>
                                    <Select
                                        id="Workspace-select"
                                        labelId="Workspace-label"
                                        label={t("Workspace", { ns: "projects" })}
                                        value={workspaceFilter}
                                        onChange={(e) => setWorkspaceFilter(e.target.value as string)}
                                        required
                                    >
                                        {!workspaces || workspaces.length === 0 ? (
                                            <MenuItem value={""}>{t("DropdownOptionNone")}</MenuItem>
                                        ) : (
                                            <MenuItem value={"any"}>{t("DropdownOptionAny")}</MenuItem>
                                        )}
                                        <Divider />
                                        {workspaces.map((option, index) => (
                                            <MenuItem value={option.Id} key={index}>
                                                {option.Name}
                                            </MenuItem>
                                        ))}
                                    </Select>
                                </FormControl>
                            </Grid>
                        </Grid>

                        <ProjectsContainer>
                            {renderProjects()}
                            {[...Array(projects?.length ?? 0)].map((_, index) => (
                                <Spacer key={index} />
                            ))}
                        </ProjectsContainer>
                    </>
                )}
            </ContentContainer>
        </Container>
    );
};

export default Projects;
