import { BcfComment } from "../Models/BcfComment";
import { BcfFullTopic } from "../Models/BcfFullTopic";
import { BcfViewpoint } from "../Models/BcfViewpoint";
import { ExportOptions } from "../Models/ExportOptions";
import { IfcPublishOptions } from "../Models/IfcPublishOptions";
import { ISynchronisationElement } from "../Models/ISynchronisationElement";
import { IHandler } from "../Shared/IHandler";

export class ArchicadHandler implements IHandler {
    private pluginObj: any;

    constructor() {
        this.pluginObj = window.archicad;
    }

    //
    // Plugin
    //

    public getPluginVersion = (): Promise<string> => {
        return this.pluginObj.getPluginVersionAsync();
    };

    public getActiveDocument = (): Promise<string> => {
        return this.pluginObj.getActiveDocument();
    };

    public openWindow = (identifier: string, url: string, height: number, width: number): Promise<string> => {
        return this.pluginObj.openWindow(JSON.stringify({ url, height, width, windowIdentifier: identifier }));
    };

    public openBrowser = (url: string): Promise<string> => {
        return this.pluginObj.openUrlInBrowser(url);
    };

    public closeWindow = (identifier: string): Promise<string> => {
        return this.pluginObj.closeWindow(identifier);
    };

    //
    // User
    //

    public getToken = (): Promise<boolean> => {
        return this.pluginObj.getToken();
    };

    public getUserInfo = (): Promise<string> => {
        return this.pluginObj.getUserInfo();
    };

    //
    // Project
    //

    public getDocumentStatus = (): Promise<string> => {
        return this.pluginObj.getDocumentStatus();
    };

    public getWorkspaces = (): Promise<string> => {
        return this.pluginObj.getWorkspaces();
    };

    public getProjects = (): Promise<string> => {
        return this.pluginObj.getProjects();
    };

    public setActiveProject = (cloudId: number, projectId: number): Promise<string> => {
        return this.pluginObj.setActiveProject(JSON.stringify({ cloudId, projectId }));
    };

    //
    // DMS
    //

    public getDmsFolderStructure = (cloudId: number, projectId: number): Promise<string> => {
        return this.pluginObj.getDmsFolderStructure(JSON.stringify({ cloudId, projectId }));
    };

    //
    // Publish IFC
    //

    public getAvailableIfcExportOptions = (): Promise<string> => {
        return this.pluginObj.getAvailableIfcExportOptions();
    };

    public publishIfc = (options: IfcPublishOptions): Promise<string> => {
        return this.pluginObj.exportIfc(JSON.stringify(options));
    };

    //
    // Export 2D (Views or sheets)
    //

    public verifyPrinter2D = (): Promise<string> => {
        return this.pluginObj.verifyPrinter2D();
    };

    public getDwgTargets = (): Promise<string> => {
        return this.pluginObj.getDwgTargets();
    };

    public getPdfTargets = (): Promise<string> => {
        return this.pluginObj.getPdfTargets();
    };

    public getExport2DOptions = (): Promise<string> => {
        return this.pluginObj.getExport2DOptions();
    };

    public checkReadiness = (options: ExportOptions): Promise<boolean> => {
        return this.pluginObj.checkReadiness(JSON.stringify(options));
    };

    public export2D = (options: ExportOptions): Promise<string> => {
        return this.pluginObj.export2D(JSON.stringify(options));
    };

    //
    // Synchronisation
    //

    public getSynchronizationState = (cloudId: number, projectId: number, ifcId: number, translator: string): Promise<string> => {
        return this.pluginObj.getSynchronizationState(JSON.stringify({ cloudId, projectId, ifcId, exportOptions: translator }));
    };

    public synchronize = (
        cloudId: number,
        projectId: number,
        ifcId: number,
        elements: Array<ISynchronisationElement>,
        importTranslator: string
    ): Promise<string> => {
        return this.pluginObj.synchronize(JSON.stringify({ cloudId, projectId, ifcId, elements, importTranslator }));
    };

    public getImportTranslators = (): Promise<string> => {
        return this.pluginObj.getImportTranslators();
    };

    //
    // BCF
    //

    public getBcfExtensions = (cloudId: number, projectId: number): Promise<string> => {
        return this.pluginObj.getBcfExtensions(JSON.stringify({ cloudId, projectId }));
    };

    public getBcfTopics = (cloudId: number, projectId: number): Promise<string> => {
        return this.pluginObj.getBcfTopics(JSON.stringify({ cloudId, projectId }));
    };

    public getBcfTopic = (cloudId: number, projectId: number, topicGuid: string): Promise<string> => {
        return this.pluginObj.getBcfTopic(JSON.stringify({ cloudId, projectId, topicGuid }));
    };

    public createNewTopic = (cloudId: number, projectId: number, topic: BcfFullTopic): Promise<string> => {
        return this.pluginObj.createNewTopic(JSON.stringify({ cloudId, projectId, topic }));
    };

    public updateTopic = (cloudId: number, projectId: number, topic: BcfFullTopic): Promise<string> => {
        return this.pluginObj.updateTopic(JSON.stringify({ cloudId, projectId, topic }));
    };

    public removeTopic = (cloudId: number, projectId: number, topicGuid: string): Promise<string> => {
        return this.pluginObj.removeTopic(JSON.stringify({ cloudId, projectId, topicGuid }));
    };

    public extractViewpoint = (): Promise<string> => {
        return this.pluginObj.extractViewpoint();
    };

    public showViewpoint = (viewpoint: BcfViewpoint): Promise<string> => {
        return this.pluginObj.showViewpoint(JSON.stringify(viewpoint));
    };

    public addComment = (cloudId: number, projectId: number, topicGuid: string, comment: BcfComment): Promise<string> => {
        return this.pluginObj.addComment(JSON.stringify({ cloudId, projectId, topicId: topicGuid, comment }));
    };

    public updateComment = (): Promise<string> => {
        return this.pluginObj.updateComment();
    };

    public removeComment = (): Promise<string> => {
        return this.pluginObj.removeComment();
    };

    //
    // Revit
    //

    public highlightElement = (elementId: number | string): Promise<void> => {
        return this.pluginObj.highlightElement(elementId);
    };
}
