import { styled } from "@mui/material/styles";
import { Outlet } from "react-router-dom";
import Header from "../Header/Header";

const Container = styled("div")(() => ({
    position: "relative",
    display: "flex",
    flexDirection: "column",
    height: "calc(100vh - 80px)",
    userSelect: "none",
}));

const Page = () => {
    return (
        <>
            <Header />
            <Container>
                <Outlet />
            </Container>
        </>
    );
};

export default Page;
