import i18n from "i18next";
import { initReactI18next } from "react-i18next";

import en from "./locales/en/resources";
import fr from "./locales/fr/resources";

i18n.use(initReactI18next).init({
    debug: true,
    fallbackLng: "dev",
    lng: "en",
    resources: {
        en,
        fr,
    },
    interpolation: {
        escapeValue: false,
    },
    defaultNS: "common",
    returnEmptyString: false,
    keySeparator: ".",
});
