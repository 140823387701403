import { useEffect, useLayoutEffect, useState } from "react";
import { Outlet, useParams } from "react-router-dom";
import { CssBaseline, Theme, ThemeProvider } from "@mui/material";
import { PluginHandlerContext } from "./Contexts/PluginHandler/PluginHandlerContext";
import PluginDataProvider from "./Contexts/PluginData/PluginDataContext";
import { PluginHandler } from "./Connectors/Shared/PluginHandler";
import LoadingOverlay from "./Components/LoadingOverlay/LoadingOverlay";

import { theme as bimDataTheme } from "./Theme/BimDataTheme";
import { theme as idCaptureTheme } from "./Theme/IdCaptureTheme";

const App = () => {
    const [loading, setLoading] = useState<boolean>(true);
    const [plugin, setPlugin] = useState<PluginHandler>();
    const [theme, setTheme] = useState<Theme>(bimDataTheme);
    const params = useParams();

    useLayoutEffect(() => {
        PluginHandler.create().then((handler) => {
            setLoading(false);
            setPlugin(handler);
        });
    }, []);

    useEffect(() => {
        switch (params.company) {
            case "id-capture":
                setTheme(idCaptureTheme);
                break;

            default:
                setTheme(bimDataTheme);
        }
    }, [params.company]);

    return (
        <PluginHandlerContext.Provider value={{ plugin }}>
            <PluginDataProvider>
                <ThemeProvider theme={theme}>
                    <CssBaseline />
                    {loading ? <LoadingOverlay active={loading} /> : <Outlet />}
                </ThemeProvider>
            </PluginDataProvider>
        </PluginHandlerContext.Provider>
    );
};

export default App;
