import { BcfComment } from "../Models/BcfComment";
import { BcfFullTopic } from "../Models/BcfFullTopic";
import { BcfViewpoint } from "../Models/BcfViewpoint";
import { ExportOptions } from "../Models/ExportOptions";
import { IfcPublishOptions } from "../Models/IfcPublishOptions";
import { ISynchronisationElement } from "../Models/ISynchronisationElement";
import { IHandler } from "../Shared/IHandler";

export class RevitHandler implements IHandler {
    private pluginObj: any;

    constructor() {
        this.pluginObj = window.revit;
    }

    //
    // Plugin
    //

    public getPluginVersion = (): Promise<string> => {
        return new Promise((resolve, reject) => this.pluginObj.getPluginVersionAsync(resolve, reject));
    };

    public getActiveDocument = (): Promise<string> => {
        return new Promise((resolve, reject) => this.pluginObj.getActiveDocument(resolve, reject));
    };

    public openWindow = (identifier: string, url: string, height: number, width: number): Promise<string> => {
        return new Promise((resolve, reject) => this.pluginObj.openWindow({ url, height, width, windowIdentifier: identifier }, resolve, reject));
    };

    public openBrowser = (url: string): Promise<string> => {
        return new Promise((resolve, reject) => this.pluginObj.openUrlInBrowser(url, resolve, reject));
    };

    public closeWindow = (identifier: string): Promise<string> => {
        return new Promise((resolve, reject) => this.pluginObj.closeWindow(identifier, resolve, reject));
    };

    //
    // User
    //

    public getToken = (): Promise<boolean> => {
        return new Promise((resolve, reject) => this.pluginObj.getToken(resolve, reject));
    };

    public getUserInfo = (): Promise<string> => {
        return new Promise((resolve, reject) => this.pluginObj.getUserInfo(resolve, reject));
    };

    //
    // Project
    //

    public getDocumentStatus = (): Promise<string> => {
        return new Promise((resolve, reject) => this.pluginObj.getDocumentStatus(resolve, reject));
    };

    public getWorkspaces = (): Promise<string> => {
        return new Promise((resolve, reject) => this.pluginObj.getWorkspaces(resolve, reject));
    };

    public getProjects = (): Promise<string> => {
        return new Promise((resolve, reject) => this.pluginObj.getProjects(resolve, reject));
    };

    public setActiveProject = (cloudId: number, projectId: number): Promise<string> => {
        return new Promise((resolve, reject) => this.pluginObj.setActiveProject({ cloudId, projectId }, resolve, reject));
    };

    //
    // DMS
    //

    public getDmsFolderStructure = (cloudId: number, projectId: number): Promise<string> => {
        return new Promise((resolve, reject) => this.pluginObj.getDmsFolderStructure({ cloudId, projectId }, resolve, reject));
    };

    //
    // Publish IFC
    //

    public getAvailableIfcExportOptions = (): Promise<string> => {
        return new Promise((resolve, reject) => this.pluginObj.getAvailableIfcExportOptions(resolve, reject));
    };

    public publishIfc = (options: IfcPublishOptions): Promise<string> => {
        return new Promise((resolve, reject) => this.pluginObj.exportIfc(options, resolve, reject));
    };

    public getImportTranslators = (): Promise<string> => {
        throw new Error("Import Translators are for Archicad only.");
    };

    //
    // Export 2D (Views or sheets)
    //

    public verifyPrinter2D = (): Promise<string> => {
        return new Promise((resolve, reject) => this.pluginObj.verifyPrinter2D(resolve, reject));
    };

    public getDwgTargets = (): Promise<string> => {
        return new Promise((resolve, reject) => this.pluginObj.getDwgTargets(resolve, reject));
    };

    public getPdfTargets = (): Promise<string> => {
        return new Promise((resolve, reject) => this.pluginObj.getPdfTargets(resolve, reject));
    };

    public getExport2DOptions = (): Promise<string> => {
        return new Promise((resolve, reject) => this.pluginObj.getExport2DOptions(resolve, reject));
    };

    public checkReadiness = (options: ExportOptions): Promise<boolean> => {
        throw Error("NotImplementedException");
    };

    public export2D = (options: ExportOptions): Promise<string> => {
        return new Promise((resolve, reject) => this.pluginObj.export2D(options, resolve, reject));
    };

    //
    // Synchronisation
    //

    public getSynchronizationState = (cloudId: number, projectId: number, ifcId: number, translator: string): Promise<string> => {
        return new Promise((resolve, reject) => this.pluginObj.getSynchronizationState({ cloudId, projectId, ifcId }, resolve, reject));
    };

    public synchronize = (
        cloudId: number,
        projectId: number,
        ifcId: number,
        elements: Array<ISynchronisationElement>,
        importTranslator: string // Unused for Revit
    ): Promise<string> => {
        return new Promise((resolve, reject) => this.pluginObj.synchronize({ cloudId, projectId, ifcId, elements }, resolve, reject));
    };

    //
    // BCF
    //

    public getBcfExtensions = (cloudId: number, projectId: number): Promise<string> => {
        return new Promise((resolve, reject) => this.pluginObj.getBcfExtensions({ cloudId, projectId }, resolve, reject));
    };

    public getBcfTopics = (cloudId: number, projectId: number): Promise<string> => {
        return new Promise((resolve, reject) => this.pluginObj.getBcfTopics({ cloudId, projectId }, resolve, reject));
    };

    public getBcfTopic = (cloudId: number, projectId: number, topicGuid: string): Promise<string> => {
        return new Promise((resolve, reject) => this.pluginObj.getBcfTopic({ cloudId, projectId, topicGuid }, resolve, reject));
    };

    public createNewTopic = (cloudId: number, projectId: number, topic: BcfFullTopic): Promise<string> => {
        return new Promise((resolve, reject) => this.pluginObj.createNewTopic({ cloudId, projectId, topic }, resolve, reject));
    };

    public updateTopic = (cloudId: number, projectId: number, topic: BcfFullTopic): Promise<string> => {
        return new Promise((resolve, reject) => this.pluginObj.updateTopic({ cloudId, projectId, topic }, resolve, reject));
    };

    public removeTopic = (cloudId: number, projectId: number, topicGuid: string): Promise<string> => {
        return new Promise((resolve, reject) => this.pluginObj.removeTopic({ cloudId, projectId, topicGuid }, resolve, reject));
    };

    public extractViewpoint = (): Promise<string> => {
        return new Promise((resolve, reject) => this.pluginObj.extractViewpoint(resolve, reject));
    };

    public showViewpoint = (viewpoint: BcfViewpoint): Promise<string> => {
        return new Promise((resolve, reject) => this.pluginObj.showViewpoint(viewpoint, resolve, reject));
    };

    public addComment = (cloudId: number, projectId: number, topicGuid: string, comment: BcfComment): Promise<string> => {
        return new Promise((resolve, reject) => this.pluginObj.addComment({ cloudId, projectId, topicId: topicGuid, comment }, resolve, reject));
    };

    public updateComment = (): Promise<string> => {
        return new Promise((resolve, reject) => this.pluginObj.updateComment(resolve, reject));
    };

    public removeComment = (): Promise<string> => {
        return new Promise((resolve, reject) => this.pluginObj.removeComment(resolve, reject));
    };

    //
    // Revit
    //

    public highlightElement = (elementId: number | string): Promise<void> => {
        return new Promise((resolve, reject) => this.pluginObj.highlightElement(elementId, resolve, reject));
    };
}
